import React, {useState, useEffect} from "react";
import objectHash from "object-hash";
import ShowImage from "../../Images/ShowImage";
import {useHistory} from "react-router"
import ViewSubNodeValues from "./ViewSubNodeValues";


const MapSubNodeValues = ({node, RecordKey, setToggleRefreshData}) => {
// Variables
    const history = useHistory();
    const [mynumber, setMynumber] = useState("");
    const [myrequested, setRequested] = useState("");
    const [showSubNodes, setShowSubnodes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const [nodes, setNodes] = useState([]);

    const handleShowSubNodes = () => {
        setShowSubnodes(!showSubNodes)
    }

    function gotoNode(id) {
        history.push('/edit/' + id);
        setToggleRefreshData(true);
        window.location.reload();
    }

    const hashTriple = (triple) => {
        return objectHash(triple)
    }


    return (
        <span>
            {node.returnValues.map((innerNode,i) => (
                <ViewSubNodeValues key={i} innerNode={innerNode} RecordKey={RecordKey} setToggleRefreshData={setToggleRefreshData}/>
            ))}
            {node.nodeType === "ImageObject" &&
            <span><ShowImage identifier={node.recordID} lowRes={true} fileType="image/jpeg" fileUrl={null}/></span>
            }
        </span>
    );
};
export default MapSubNodeValues;

// <Link to={"/edit/" + RecordKey} className="nav-link">{RecordKey}</Link>
// <button type="button" className="btn btn-primary" onClick={() => gotoNode(RecordKey)}>Edit record</button>

import React from "react";
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading, SubHeading, FooterDiv,
} from "./FooterStyles";
import PrivacyPolicy from "./Privicy-policy/PrivacyPolicy";


const Footer = () => {
    return (
        <FooterDiv>
            <Row>
                <Column>
                    <SubHeading>About Us</SubHeading>
                    <FooterLink href="https://www.datamachine.info">Datamachine Website</FooterLink>
                </Column>
                <Column>
                    <SubHeading>Services</SubHeading>
                    <FooterLink href="https://www.datamachine.info#EnumeratedListRight">Knowledge Graph</FooterLink>
                    <FooterLink href="https://www.datamachine.info#EnumeratedListRight">VisualSearch</FooterLink>
                    <FooterLink href="https://www.datamachine.online">Imageviewer Login</FooterLink>
                </Column>
                <Column>
                    <SubHeading>Contact Us</SubHeading>
                    <FooterLink href="https://www.datamachine.info/contact">Address</FooterLink>
                    <FooterLink href="/GeneralTerms">Gebruiksvoorwaarden</FooterLink>
                    <FooterLink href="/CookiePolicy">Cookie Policy</FooterLink>
                    <FooterLink href="/PrivacyPolicy">Privacy Policy</FooterLink>
                </Column>
                {/*    <Column>*/}
                {/*        <SubHeading>Social Media</SubHeading>*/}
                {/*        <FooterLink href="#">*/}
                {/*            <i className="fab fa-facebook-f">*/}
                {/*<span style={{ marginLeft: "10px" }}>*/}
                {/*  Facebook*/}
                {/*</span>*/}
                {/*            </i>*/}
                {/*        </FooterLink>*/}
                {/*    </Column>*/}
            </Row>
        </FooterDiv>
    );
};
export default Footer;

//mytriple={mytriple} updateNode={updateNode} instructionType={instructionType} setIsLoading={setIsLoading} setIsSuccess={setIsSuccess}

import React from "react";
import UserService from "../../../services/user.service";




function Update(props) {

    console.log("Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)")
    console.log(props.mytriple)
    console.log(props.updateNode)
    console.log("props.instructionType = " + props.instructionType)
    console.log("Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)Update(props)")

    let success = false;
    props.setIsLoading(true);
    UserService.saveTriple(props.mytriple, props.updateNode, props.instructionType).then(
        (response) => {
            props.setIsLoading(false);
            console.log(response.headers)
            console.log(response.data)
            if (response.data.status === "200") {
                props.setIsSuccess(true);
            }
            else {
                props.setIsSuccess(false);
            }
        },
        (error) => {
            props.setIsLoading(false);
            props.setIsSuccess(false);
            const _content =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(error)
        }
    );
}

export default Update

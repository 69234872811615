import React, {useState, useEffect} from "react";

import ShowImage from "../../Images/ShowImage";
import {useHistory} from "react-router"
import editstyle from "../../../css/edittriple.module.css";
import iconstyle from "../../../css/icons.module.css";
import {ReactComponent as EmptyIcon} from "../../Icons/EmptyIcon.svg";
import {ReactComponent as ShowMoreIcon} from "../../Icons/ShowMore.svg";
import SelectSubNodes from "../SelectSubNodes";
import ReactTooltip from 'react-tooltip';
import {ReactComponent as AddPredicateSelectedIcon} from "../../Icons/AddPredicateSelected.svg";


const ViewSubNodeValues = ({innerNode, RecordKey, setToggleRefreshData}) => {
// Variables
    const history = useHistory();
    const [showSubNodes, setShowSubnodes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const [nodes, setNodes] = useState([]);

    const handleShowSubNodes = () => {
        setShowSubnodes(!showSubNodes)
    }

    function gotoNode(id) {
        history.push('/edit/' + id);
        setToggleRefreshData(true);
        window.location.reload();
    }


    return (
        <span>
            <div key={innerNode.value}>
                {innerNode.fieldName.endsWith("type") ?
                    <div className={editstyle.showInlineType}>
                        <a style={{ cursor: "pointer" }} data-tip data-delay-show='1000' data-for="GotoNode" onClick={() => gotoNode(RecordKey)}><strong>Type:</strong>&nbsp;&nbsp;&nbsp;&nbsp;{innerNode.value}
                            <ReactTooltip id="GotoNode" type="error" place="bottom">
                                <span>Goto node</span>
                            </ReactTooltip></a>
                    </div>
                    :
                    <div className={editstyle.showInlineProperty}>

                        {(innerNode.internalLink) ?
                            <a className={iconstyle.suggestionButton} onClick={() => handleShowSubNodes()}> <ShowMoreIcon/> </a>
                            :
                            <a className={iconstyle.suggestionFiller}><EmptyIcon/></a>
                        }

                        <p><strong>Fieldname:&nbsp;&nbsp;</strong></p>
                        <p className={editstyle.fieldname}>{innerNode.fieldName}</p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <p><strong>Value:&nbsp;&nbsp;</strong></p>
                        <p>
                            {innerNode.valueType !== "iri" && <span>{innerNode.value}</span>}
                            {innerNode.internalLink && <a onClick={() => gotoNode(innerNode.value)} className={editstyle.clickable}>{innerNode.value}</a>}
                            {!innerNode.internalLink && innerNode.valueType === "iri" && <a href={innerNode.value} target="_blank" className={editstyle.clickable}>{innerNode.value}</a>}
                        </p>

                        {innerNode.languageOfValue && <p>&nbsp;&nbsp;&nbsp;<strong>Language:&nbsp;&nbsp;</strong> {innerNode.languageOfValue}</p>}
                        {(showSubNodes) &&
                        <span>
                            <SelectSubNodes identifier={innerNode.value} onlyValues={false} setToggleRefreshData={setToggleRefreshData} setIsLoading={setIsLoading} level={0}/>
                        </span>
                        }
                    </div>
                }
                </div>
            {innerNode.nodeType === "ImageObject" &&
            <span>
                {console.log("I enter image + " + innerNode.recordID)}
                <ShowImage identifier={innerNode.recordID} lowRes={true} fileType="image/jpeg" fileUrl={null}/>
            </span>
            }
        </span>
    );
};
export default ViewSubNodeValues;

// <Link to={"/edit/" + RecordKey} className="nav-link">{RecordKey}</Link>
// <button type="button" className="btn btn-primary" onClick={() => gotoNode(RecordKey)}>Edit record</button>

import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import UserService from "../../services/user.service";
import ReturnRecordEdit from "./ReturnRecordEdit";
import SelectSubNodes from "./SelectSubNodes";
import AuthService from "../../services/auth.service";
import {Helmet} from "react-helmet";
import Suggestions from "./Suggestions";


const Edit = () => {
// Variables
    const history = useHistory();
    const [mynumber, setMynumber] = useState("");
    const [myRequested, setMyRequested] = useState("");
    const [returnRecords, setReturnRecords] = useState([]);
    const [referringNodes, setReferringNodes] = useState([]);
    let urlElements = window.location.href.split('/');
    const lastItem = urlElements[urlElements.length - 1];
    const [isLoading, setIsLoading] = useState(false);
    const [toggleRefreshData, setToggleRefreshData] = useState(true);
    const [showSubNodes, setShowSubnodes] = useState(false);
    const [referingNodesFound, setReferingNodesFound] = useState(false);
    const [showSubNodesButtonText, setShowSubnodesButtonText] = useState("Show");
    const [isImageObject, setIsImageObject] = useState(false);
    const [imageUrlArray, setImageUrlArray] = useState([]);
    const [rdfPrefix, setRdfPrefix] = useState([]);
    const [hasImage, setHasImage] = useState(false);
    const [contentUrlImageTriple, setContentUrlImageTriple] = useState([])
    const [busyMatching, setBusyMatching]=useState(false);

    const logOut = () => {
        AuthService.logout();
        history.push("/");
        window.location.reload();
    };

    const handleShowSubNodes = () => {
        setShowSubnodes(!showSubNodes)
        if (showSubNodes) {
            setShowSubnodesButtonText("Show")
        } else {
            setShowSubnodesButtonText("Hide")
        }

    }

    useEffect(() => {
        if (toggleRefreshData) {
            setIsLoading(true);
            UserService.getRecord(lastItem, "0", "0").then(
                (response) => {
                    // console.log("START Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component")
                    // console.log(response.data)
                    if (response.data.status === "404") {
                        history.push("/");
                    }
                    if (response.data.numberOfRecords === 0) {
                        history.push("/");
                    }

                    setMynumber(response.data.numberOfRecords)
                    setMyRequested(response.data.searchString)
                    console.log("------------------------------------------------>>>>>>>>>>>>record.toString() = ")
                    console.log("response.data.returnRecord = ")
                    console.log(response.data.returnRecord)
                    for (let i = 0; i < response.data.returnRecord.length; i++) {
                        console.log(response.data.returnRecord[i].nodeType)
                        if (response.data.returnRecord[i].nodeType.toString() === ("ImageObject")) {
                            setIsImageObject(true)
                            imageUrlArray.push(response.data.returnRecord[i].recordID.toString().substring(response.data.returnRecord[i].recordID.toString().indexOf(":") + 1, response.data.returnRecord[i].recordID.toString().length))
                            rdfPrefix.push(response.data.returnRecord[i].recordID.toString().substring(0, response.data.returnRecord[i].recordID.toString().indexOf(":")))
                            console.log("rdfPrefix.push = " + response.data.returnRecord[i].recordID.toString().substring(0, response.data.returnRecord[i].recordID.toString().indexOf(":")))
                            // imageUrlArray.push(response.data.returnRecord[i].recordID.toString())
                        }
                        for (let j = 0; j < response.data.returnRecord[i].returnValues.length; j++) {
                            console.log(response.data.returnRecord[i].returnValues[j].doNotEdit.toString())
                            console.log("busyMatching = " + busyMatching.toString())
                            if (busyMatching){
                                response.data.returnRecord[i].returnValues[j].doNotEdit = true
                                console.log("response.data.returnRecord[i].returnValues[j].doNotEdit.toString()")
                                console.log(response.data.returnRecord[i].returnValues[j].doNotEdit.toString())
                            }
                            if (response.data.returnRecord[i].returnValues[j].fieldName === "schema:contentUrl") {
                                console.log("*****************************************************************************************")
                                console.log("FOUND CONTENTURL!!!!!!!")
                                console.log("*****************************************************************************************")

                                setContentUrlImageTriple(response.data.returnRecord[i].returnValues[j])
                                setHasImage(true);
                            }
                        }
                    }
                    setReturnRecords(response.data.returnRecord);
                    // if (response.data.returnRecord.nodeType.contains("ImageObject")) {
                    //     setIsImageObject(true)
                    //     imageUrlArray.push(response.data.returnRecord.identifier)
                    // }

                    // console.log("searchString = " + response.data.searchString)
                    // console.log("status = " + response.data.status)
                    // console.log("timeStamp = " + response.data.timeStamp)
                    // console.log("response.data.returnRecord start = ")
                    console.log(response.data.returnRecord)
                    // console.log("response.data.returnRecord end = ")
                    setIsLoading(false);
                    setToggleRefreshData(false)
                    setShowSubnodes(false)
                    if (showSubNodes) {
                        handleShowSubNodes()
                    }
                    // console.log("END Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component")
                },
                (error) => {
                    setIsLoading(false);
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setToggleRefreshData(false)
                    if (error.response.data.status === 401) {
                        logOut()
                    }
                    history.push("/");
                }
            );
        }
    }, [toggleRefreshData]);

    return (
        <span>
            <Helmet>
                <title>Edit {myRequested}</title>
            </Helmet>

            <div className="headDivToolbar">
                <h3>Edit {isLoading && <>Loading...</>}</h3>
                    <h4><strong>Node ID</strong> {myRequested}</h4>
            </div>
            {returnRecords.length > 0 && <span>
            {returnRecords.map(returnRecordValue => (
                <span key={returnRecordValue.recordID}>
                    <ReturnRecordEdit
                        recourCounter={returnRecordValue.recordCounter}
                        recordID={returnRecordValue.recordID}
                        score={returnRecordValue.score}
                        nodeType={returnRecordValue.nodeType}
                        triples={returnRecordValue.returnValues}
                        toggleRefreshData={toggleRefreshData}
                        setToggleRefreshData={setToggleRefreshData}
                        showSubNodes={showSubNodes}
                        handleShowSubNodes={handleShowSubNodes}
                        hasImage={hasImage}
                        contentUrlImageTriple={contentUrlImageTriple}

                    />
                    {(showSubNodes) &&
                        <span>
                        <a name={"#"}></a>
                        <SelectSubNodes identifier={returnRecordValue.recordID} onlyValues={false}
                                        setToggleRefreshData={setToggleRefreshData} setIsLoading={setIsLoading}
                                        level={3}/>
                    </span>
                    }
                </span>
            ))}
                {hasImage && <Suggestions nodes={[]} imageArray={imageUrlArray} rdfPrefix={rdfPrefix}
                                               toggleRefreshData={toggleRefreshData} setToggleRefreshData={setToggleRefreshData} setBusyMatching={setBusyMatching}/>}
            </span>
            }
        </span>
    );
};

export default Edit;

import React, {useEffect, useRef, useState} from "react";
import 'react-calendar/dist/Calendar.css';
import Update from "./TripleFunctions/Update";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as CancelIcon} from "../Icons/Cancel.svg";
import editstyle from "../../css/edittriple.module.css";
import {ReactComponent as CheckIcon} from "../Icons/Check.svg";
import ReactTooltip from 'react-tooltip';


/**
 *
 * @param mytriple
 * @param toStartEdit
 * @param setIsChanged
 * @param setToggleRefreshData
 * @param instructionType update, insert, delete, addType
 * @returns {JSX.Element}
 * @constructor
 */
const TripleDelete = ({mytriple, toStartEdit, setIsChanged, setToggleRefreshData, instructionType}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [doToggleSave, setDoToggleSave] = useState(false);
    const [updateNode, setUpdateNode] = useState({
        identifier: "",
        fieldName: "",
        value: "",
        valueType: "",
        languageOfValue: "",
        graph: "",
        doNotEdit: false,
        internalLink: false,
        imageObject: false
    });
    const isInitialMount = useRef(true);

    const cancelAction = () => {
        console.log("hier: ")
        console.log(updateNode)
        setUpdateNode({...updateNode, value: "", valueType: ""})
        setIsChanged(false)
        toStartEdit(false)
    }

    useEffect(() => {
        if (isSuccess) {
            setToggleRefreshData(true)
            cancelAction()
        } else {
            setIsChanged(false)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            Update({mytriple, updateNode, instructionType, setIsLoading, setIsSuccess})
        }
    }, [doToggleSave]);

    return (
        <span>
            {isLoading && <>Updating Please Wait...</>}
            <h4>Confirm that you want to delete this triple</h4>
            <div className={editstyle.showInlineProperty}>
                 <a data-tip data-for="CheckIcon" href="#" className={iconstyle.suggestionButton} onClick={() => setDoToggleSave(!doToggleSave)}> <CheckIcon/> </a>
                <ReactTooltip id="CheckIcon" type="error">
                    <span>CheckIcon</span>
                </ReactTooltip>
                &nbsp;&nbsp;
                <a data-tip data-for="CancelIcon" href="#" className={iconstyle.suggestionButton} onClick={() => cancelAction()}> <CancelIcon/> </a>
                <ReactTooltip id="CancelIcon" type="error">
                    <span>CancelIcon</span>
                </ReactTooltip>


            </div>
        </span>
    );
}

export default TripleDelete;

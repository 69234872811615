import React, {Component, useEffect, useState} from "react";
import mystyle from "../../css/edittriple.module.css"
import TripleEditor from "./TripleEditor";
import TripleDelete from "./TripleDelete";
import TypeDelete from "./TypeDelete";
import ViewTripleValues from "./TripleDisplayFunctions/ViewTripleValues";


/**
 * input variabelen ReturnValueEdit
 * @param mytriple ={mytriple}
 * @param setToggleRefreshData={setToggleRefreshData}
 */
class ReturnValueEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showIri: false,
            startEdit: false,
            isChanged: false,
            showDeleteMessage: false,
            instructionType: ""
        };

        // this.toStartEdit = this.toStartEdit.bind(this)
    }


    toggleShowIri = () => {
        this.setState(prevState => {
            return {showIri: !prevState.showIri}
        })
    }

    toStartEdit = () => {
        this.setInstructionType("update")
        this.setState(prevState => {
            return {startEdit: !prevState.startEdit}
        })
    }

    setIsChanged = (newValue) => {
        this.setState(() => {
            return {isChanged: newValue}
        })
    }

    setInstructionType = (newValue) => {
        this.setState(() => {
            return {instructionType: newValue}
        })
    }

    deleteTriple = () => {
        {
            this.setInstructionType("delete")
            this.setState(prevState => {
                return {startEdit: !prevState.startEdit}
            })
        }
    }

    render() {
        const {showIri, startEdit, isChanged, instructionType} = this.state;

        let buttonText = "Triple"
        if (this.props.mytriple.fieldName.endsWith(":type")) {
            buttonText = "Type"
        }
        return (
            <span>
                <ViewTripleValues
                    key={this.props.id}
                    node={this.props.mytriple}
                    RecordKey={this.props.key}
                    toggleRefreshData={this.props.toggleRefreshData}
                    setToggleRefreshData={this.props.setToggleRefreshData}
                    doNotEdit={this.props.mytriple.doNotEdit}
                    startEdit={startEdit}
                    deleteTriple={this.deleteTriple}
                    toSTartEdit={this.toStartEdit}
                    setFileUrl={this.props.setFileUrl}
                />
                {startEdit && instructionType === "update" && <TripleEditor key={this.props.key} id={this.props.id} mytriple={this.props.mytriple} toStartEdit={this.toStartEdit} setIsChanged={this.setIsChanged} setToggleRefreshData={this.props.setToggleRefreshData} instructionType={instructionType}/>}
                {startEdit && instructionType === "delete" && buttonText === "Type" && <TypeDelete mytriple={this.props.mytriple} toStartEdit={this.toStartEdit} setIsChanged={this.setIsChanged} setToggleRefreshData={this.props.setToggleRefreshData} instructionType={instructionType}/>}
                {startEdit && instructionType === "delete" && buttonText === "Triple" && <TripleDelete mytriple={this.props.mytriple} toStartEdit={this.toStartEdit} setIsChanged={this.setIsChanged} setToggleRefreshData={this.props.setToggleRefreshData} instructionType={instructionType}/>}
            </span>
        )
    }
};
export default ReturnValueEdit

import React, {useState} from "react";
import {
    ContentState,
    convertToRaw,
    convertFromHTML,
    CompositeDecorator,
    EditorState
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import "../../../css/TextEditor.css";



function TextEditor(props) {

    const customContentStateConverter = (contentState) => {
        // changes block type of images to 'atomic'
        const newBlockMap = contentState.getBlockMap().map((block) => {
            const entityKey = block.getEntityAt(0);
            if (entityKey !== null) {
                const entityBlock = contentState.getEntity(entityKey);
                const entityType = entityBlock.getType();
                switch (entityType) {
                    case 'IMAGE': {
                        const newBlock = block.merge({
                            type: 'atomic',
                            text: 'img',
                        });
                        return newBlock;
                    }
                    default:
                        return block;
                }
            }
            return block;
        });
        const newContentState = contentState.set('blockMap', newBlockMap);
        return newContentState;
    }



    // function findLinkEntities(contentBlock, callback, contentState) {
    //     console.log(contentBlock.getData());
    //     contentBlock.findEntityRanges((character) => {
    //         console.log("character: ", character.getStyle());
    //         console.log("character: ", character.getEntity());
    //         const entityKey = character.getEntity();
    //         return (
    //             entityKey !== null &&
    //             contentState.getEntity(entityKey).getType() === "LINK"
    //         );
    //     }, callback);
    // }
    //
    // const Link = (props) => {
    //     const {url} = props.contentState.getEntity(props.entityKey).getData();
    //     return <a href={url}>{props.children}</a>;
    // };
    //
    // const decorator = new CompositeDecorator([
    //     {
    //         strategy: findLinkEntities,
    //         component: Link
    //     }
    // ]);
    //
    // // const sampleMarkup = "<div><a href='http:tada'>hihi</a></div>";
    // const sampleMarkup = props.updateNode.value;
    // const blocksFromHTML = convertFromHTML(sampleMarkup);
    //
    //
    //
    // const state = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap
    // );
    // console.log(convertToRaw(state));


    // const [editorState, setEditorState] = useState(() =>
    //     EditorState.createWithContent(state, decorator)
    // );
    const blocksFromHTML = convertFromHTML(props.updateNode.value);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(customContentStateConverter(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
        ))
    );
    const [text, setText] = useState();
    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);
        const {blocks} = convertToRaw(editorState.getCurrentContent());
        /*let text = blocks.reduce((acc, item) => {
          acc = acc + item.text;
          return acc;
        }, "");*/
        let text = editorState.getCurrentContent().getPlainText("\u0001");
        setText(text);
        props.setUpdateNode({...props.updateNode, value: draftToHtml(convertToRaw(editorState.getCurrentContent()))})
        props.setIsChanged(true)
        console.log("text START")
        console.log(text)
        console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        console.log("text END")
    };



    return (
        <div className="App">
            <Editor
                editorState={editorState}
                toolbarClassName="toolbar-class"
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['inline', 'blockType', 'list', 'link', 'image', 'remove', 'history'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
                    },
                    blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    },
                    image: {
                        urlEnabled: true,
                        uploadEnabled: false,
                        alignmentEnabled: false,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
                        alt: { present: true, mandatory: false },
                        defaultSize: {
                            height: 'auto',
                            width: 'auto',
                        },
                    },
                }}
            />
        </div>
    );
};

export default TextEditor

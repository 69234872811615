//isBC={isBC} setIsBC={setIsBC} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged}
import moment from "moment";
import DatePicker from "react-date-picker";
import React, {useState} from "react";
import editStyle from "../../../css/edittriple.module.css";

function EditDate(props) {
    const [dateState, setDateState] = useState(new Date())

    const handleBC = (check) => {
        props.setIsBC(check)
    }

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }

    const changeDate = (e) => {
        setDateState(e)
        let myDate = moment(e).format('YYYY-MM-DD')
        if (props.isBC) {
            myDate = myDate + " BC"
        } else {
            myDate = myDate + " AD"
        }
        props.setUpdateNode({...props.updateNode, value: myDate, valueType: "Date"})
        props.setIsChanged(true)
    }

    return (
        <label className={editStyle.editInline}>
            <input name={props.id} type={"radio"} value={"Date"}
                   checked={props.updateNode.valueType === "Date"}
                   onChange={() => handleChangeType("Date")}/>
            &nbsp;Date<br/>
            {props.updateNode.valueType === "Date" &&
            <>
                Selected
                date: {moment(dateState).format('MMMM Do YYYY')}&nbsp;&nbsp;
                <input type={"checkbox"} checked={props.isBC}
                       onChange={() => handleBC(!props.isBC)}/>
                &nbsp;BC
                <DatePicker
                    value={dateState}
                    onChange={changeDate}
                    minDetail='century'
                    isOpen={true}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    calendarType='ISO 8601'
                />
            </>
            }
        </label>
    )
}

export default EditDate

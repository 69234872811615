import React, {useState} from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import ManagementService from "../services/admin.service"

const Profile = () => {
        const currentUser = AuthService.getCurrentUser();
        const [graphBatchRw, setGraphBatchRw] = useState("");

        const [mynumber, setMynumber] = useState(-1);
        const [myrequested, setRequested] = useState("");
        const [nodes, setNodes] = useState([]);
        const [search, setSearch] = useState("");
        const [users, setUsers] = useState([]);

        // getAndMoveImage

        const getSearch = () => {
            // e.preventDefault();
            UserService.getAndMoveImage().then(
                (response) => {
                    console.log(response.data)
                    setNodes(response.data.returnRecord);
                    setMynumber(response.data.numberOfRecords)
                    // setKey(response.data.requested);
                    setRequested(response.data.searchString)
                    console.log("searchString = " + response.data.searchString)
                    console.log("status = " + response.data.status)
                    console.log("timeStamp = " + response.data.timeStamp)
                    // const data = response.data;
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    // setContent2(_content);
                }
            );
        }

        const syncElastic = () => {
            // e.preventDefault();
            UserService.syncElastic().then(
                (response) => {
                    console.log(response.data)
                    setNodes(response.data.returnRecord);
                    setMynumber(response.data.numberOfRecords)
                    // setKey(response.data.requested);
                    setRequested(response.data.searchString)
                    console.log("searchString = " + response.data.searchString)
                    console.log("status = " + response.data.status)
                    console.log("timeStamp = " + response.data.timeStamp)
                    // const data = response.data;
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    // setContent2(_content);
                }
            );
        }

        const userManagement = () => {
            ManagementService.userManagement().then(
                (response) => {
                    console.log("START userManagement")
                    console.log(response.data)
                    console.log("START userManagement")
                    setUsers(response.data)
                },
                (error) => {

                }
            );
        }

        return (
            <div className="headDiv">
                <div className="bg-light p-5 rounded-lg m-3">
                    <h3>User profile</h3>
                </div>
                <p><strong>Login name: </strong>&nbsp;&nbsp;{currentUser.username}</p><br/>
                <p>
                    <strong>Email address:</strong>&nbsp;&nbsp;{currentUser.email}
                </p><br/>
                <p><strong>Authorities for {currentUser.username}:</strong></p><br/>
                <p>
                    <ol>
                        {currentUser.roles &&
                        currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                    </ol>
                </p>
            </div>
        );
    }
;

export default Profile;

import React, {useState, useEffect} from "react";
import backgroundImage from "../components/Icons/datamachineBackground.svg"
import {Heading} from "./FooterStyles";



const Home = () => {
    const [content, setContent] = useState("DataMachine VisualSearch");
    const [content2, setContent2] = useState("Visit our website to learn more");

    return (
        <Heading>
            <h3>{content}</h3>
            <h4><a href="https://datamachine.info">{content2}</a></h4>
            {/*<img className='bg' src={backgroundImage} />*/}
        </Heading>
    );
};

export default Home;

// export const urlHeader = "http://localhost:8443/api/test/";
// export const authHeader = "http://localhost:8443/api/auth/";
export const urlHeader = "https://www.datamachine.nl:443/workbench/api/test/";
export const authHeader = "https://www.datamachine.nl:443/workbench/api/auth/";
// export const urlHeader = "http://10.0.0.159:8443/workbench/api/test/";
// export const authHeader = "http://10.0.0.159:8443/workbench/api/auth/";
// export const urlHeader = "http://10.0.0.160:8443/workbench/api/test/";
// export const authHeader = "http://10.0.0.160:8443/workbench/api/auth/";
// export const urlHeader = "https://s3.nmm-soesterberg.nl/workbench/api/test/";
// export const authHeader = "https://s3.nmm-soesterberg.nl/workbench/api/auth/";


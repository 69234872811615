import React, {useState, useEffect} from "react";
import {useHistory} from "react-router"
import UserService from "../../services/user.service";
import MapSubNodeValues from "./TripleDisplayFunctions/MapSubNodeValues";
import editstyle from "../../css/edittriple.module.css";
import objectHash from "object-hash";

/**
 *
 * @param identifier
 * @param onlyValues
 * @param setToggleRefreshData
 * @param level
 * @param setIsLoading
 * @returns {JSX.Element}
 * @constructor
 */
const SelectSubNodes = ({identifier, onlyValues, setToggleRefreshData, level, setIsLoading}) => {
// Variables
    const history = useHistory();
    const [mynumber, setMynumber] = useState("");
    const [myrequested, setRequested] = useState("");
    const [nodes, setNodes] = useState([]);
    const [status, setStatus] = useState(0);
    const [accompanyingText, setAccompanyingText] = useState("");
    const [numberOfRecords, setNumberOfRecords] = useState(0);

    const hashTriple = (triple) => {
        return objectHash(triple)
    }

    function gotoNode(id) {
        history.push('/edit/' + id);
        setToggleRefreshData(true);
        window.location.reload();
    }


    useEffect(() => {
        setIsLoading(true)
        let type = 0;
        // let level = 2;
        if (onlyValues) {
            type = 1;
        }
        UserService.getRecord(identifier, level, type).then(
            (response) => {
                console.log("ViewSubNodeValues")
                console.log(response.data)
                setNodes(response.data.returnRecord);
                setStatus(response.data.status);
                setAccompanyingText(response.data.accompanyingText);
                setNumberOfRecords(response.data.numberOfRecords);
                setIsLoading(false)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setIsLoading(false)
                // setContent2(_content);
            }
        );
    }, []);

    return (
        <span>
            {status === "417" && <p className="seperatorBanner"><strong>------>>>>{accompanyingText}</strong></p>}
            {status === "200" && level === 3 &&
            <span>
                {numberOfRecords === 1 ?
                    <div className={editstyle.showInlineType}><strong>There is {numberOfRecords} referring node:</strong></div>
                    :
                    <div className={editstyle.showInlineType}><strong>There are {numberOfRecords} referring nodes:</strong></div>
                }
            </span>
            }
            <br/><br/>
            {numberOfRecords > 0 &&
            nodes.map((node,i) => (
                <div className="firstdiv" key={i}>
                    {level === 0 && <p className="seperatorBanner"><strong>Content of node</strong>&nbsp;&nbsp;<a onClick={() => gotoNode(node.recordID)} className={editstyle.clickable}>{node.recordID}</a></p>}
                    {level === 2 && <p className="seperatorBanner"><strong>Referring node</strong>&nbsp;&nbsp;<a onClick={() => gotoNode(node.recordID)} className={editstyle.clickable}>{node.recordID}</a></p>}
                    <MapSubNodeValues node={node} RecordKey={node.recordID} setToggleRefreshData={setToggleRefreshData}/>
                </div>
            ))
            }
        </span>
    );
};
export default SelectSubNodes;


//<ViewSubNodeValues node={{node}}/>

import React, {useEffect, useState} from "react";
// import objectHash from "object-hash";
import {useHistory} from "react-router"
import ShowImage from "../Images/ShowImage";
import nodestyle from "../../css/node.module.css"
import iconstyle from "../../css/icons.module.css";
import editstyle from "../../css/edittriple.module.css"
import {ReactComponent as EditIcon} from "../Icons/Edit.svg";
import {ReactComponent as ConnectNodesIcon} from "../Icons/ConnectNodes.svg";
import {ReactComponent as CheckIcon} from "../Icons/Check.svg";
import ReactTooltip from 'react-tooltip';


/**
 *
 * @param recordCounter
 * @param recordID
 * @param score
 * @param nodeType
 * @param triples
 * @param link True of False, True: alleen een link teruggeven. False: De node editten
 * @param setLink De id van de node setten
 * @returns {JSX.Element}
 * @constructor
 */
const SearchNodeDisplay = ({recordCounter, recordID, score, nodeType, triples, link, setLink}) => {

    const history = useHistory();
    // const [search, setSearch] = useState("");
    // const [query, setQuery] = useState("");

    // const hashTriple = (triple) => {
    //     return objectHash(triple)
    // }

    function editNode(id) {
        history.push('/edit/' + id);
        window.location.reload();
    }

    function linkNode(id) {
        setLink(id)
    }

    return (

        <span className={nodestyle.node}>
            <span>
                <span className={editstyle.showInlineProperty}>
                    {link ?
                        <span><a data-tip data-for="CheckIcon" data-delay-show='1000' href="#"
                                 className={iconstyle.suggestionButton} onClick={() => linkNode(recordID)}> <CheckIcon/> </a>
                        <ReactTooltip id="CheckIcon" type="error">
                            <span>CheckIcon</span>
                        </ReactTooltip></span>
                        :
                        <span><a data-tip data-for="EditIcon" data-delay-show='1000' href="#"
                                 className={iconstyle.suggestionButton} onClick={() => editNode(recordID)}> <EditIcon/> </a>
                        <ReactTooltip id="EditIcon" type="error">
                            <span>Edit node</span>
                        </ReactTooltip></span>
                    }
                    <p><strong>Node type: </strong>{nodeType} &nbsp;&nbsp; &nbsp;&nbsp;<strong>Record id: </strong><a
                        data-tip data-for="EditIcon" data-delay-show='1000' href="#"
                        onClick={() => editNode(recordID)}>{recordID}</a></p>
                </span>
                <table className={nodestyle.datatable}>
                    <tbody>
                    <tr>
                        <th>Fieldname</th>
                        <th>Value</th>
                    </tr>
                    {triples.map((mytriple, i) => (
                        <tr key={i}>
                            <td>{mytriple.fieldName}</td>
                            <td>
                                <span dangerouslySetInnerHTML={{__html: mytriple.value}}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </span>
            <span>
                {!nodeType.includes("ImageObject") &&
                    <p>Gekoppelde afbeelding<br/></p>}
                <ShowImage identifier={recordID} lowRes={true} nodeId={true} fileType="image/jpeg" fileUrl={null}/>
            </span>

        </span>
    );
};

export default SearchNodeDisplay;

import React, {useEffect, useState} from "react";
import editstyle from "../../css/edittriple.module.css";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as DataConnectionIcon} from "../Icons/DataConnection.svg";
import {ReactComponent as ResfreshIcon} from "../Icons/Refresh.svg";
import ReactTooltip from "react-tooltip";
import UserService from "../../services/user.service";
import tableStyle from "../../css/table.module.css";
import {Helmet} from "react-helmet";


const GetUpdate = () => {
    const [numberOfRecordsStep1, setNumberOfRecordsStep1] = React.useState();
    const [numberOfRecordsStep2, setNumberOfRecordsStep2] = React.useState();
    const [numberOfRecordsStep3, setNumberOfRecordsStep3] = React.useState();
    const [numberOfRecordsStep4, setNumberOfRecordsStep4] = React.useState();
    const [numberOfRecordsStep5, setNumberOfRecordsStep5] = React.useState();
    const [percentageFinishedStep1, setPercentageFinishedStep1] = React.useState();
    const [percentageFinishedStep2, setPercentageFinishedStep2] = React.useState();
    const [percentageFinishedStep3, setPercentageFinishedStep3] = React.useState();
    const [percentageFinishedStep4, setPercentageFinishedStep4] = React.useState();
    const [percentageFinishedStep5, setPercentageFinishedStep5] = React.useState();
    const [previousStartDate, setPreviousStartDate] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [totalNumberOfRecordsStep1, setTotalNumberOfRecordsStep1] = React.useState();
    const [totalNumberOfRecordsStep2, setTotalNumberOfRecordsStep2] = React.useState();
    const [totalNumberOfRecordsStep3, setTotalNumberOfRecordsStep3] = React.useState();
    const [totalNumberOfRecordsStep4, setTotalNumberOfRecordsStep4] = React.useState();
    const [totalNumberOfRecordsStep5, setTotalNumberOfRecordsStep5] = React.useState();
    const [updateStatus, setUpdateStatus] = React.useState();
    const [updateStatusStep1, setUpdateStatusStep1] = React.useState();
    const [updateStatusStep2, setUpdateStatusStep2] = React.useState();
    const [updateStatusStep3, setUpdateStatusStep3] = React.useState();
    const [updateStatusStep4, setUpdateStatusStep4] = React.useState();
    const [updateStatusStep5, setUpdateStatusStep5] = React.useState();
    const [updateActive, setUpdateActive] = React.useState(false);
    const [isInitializing, setIsInitializing] = React.useState(false);
    const [statusMessage, setStatusMessage] = React.useState();
    const [checked0, setChecked0] = React.useState(true);
    const [checked1, setChecked1] = React.useState(true);
    const [checked2, setChecked2] = React.useState(true);
    const [checked3, setChecked3] = React.useState(true);
    const [checked4, setChecked4] = React.useState(true);
    const [opdracht, setOpdracht] = React.useState(31);
    const [message, setMessage] = React.useState("");
    const [updateStatusStructure, setUpdateStatusStructure] = React.useState(
        [{
            numberOfRecordsStep1,
            numberOfRecordsStep2,
            numberOfRecordsStep3,
            numberOfRecordsStep4,
            numberOfRecordsStep5,
            percentageFinishedStep1,
            percentageFinishedStep2,
            percentageFinishedStep3,
            percentageFinishedStep4,
            percentageFinishedStep5,
            previousStartDate,
            startDate,
            totalNumberOfRecordsStep1,
            totalNumberOfRecordsStep2,
            totalNumberOfRecordsStep3,
            totalNumberOfRecordsStep4,
            totalNumberOfRecordsStep5,
            updateActive,
            updateStatusStep1,
            updateStatusStep2,
            updateStatusStep3,
            updateStatusStep4,
            updateStatusStep5,
            message,
        }]
    );
    const [showStatus, setShowStatus] = React.useState(true);

    const handleChange0 = () => {
        if (checked0) {
            setOpdracht(opdracht - 1)
        } else {
            setOpdracht(opdracht + 1)
        }
        setChecked0(!checked0);
    };

    const handleChange1 = () => {
        if (checked1) {
            setOpdracht(opdracht - 2)
        } else {
            setOpdracht(opdracht + 2)
        }
        setChecked1(!checked1);
    };

    const handleChange2 = () => {
        if (checked2) {
            setOpdracht(opdracht - 4)
        } else {
            setOpdracht(opdracht + 4)
        }
        setChecked2(!checked2);
    };

    const handleChange3 = () => {
        if (checked3) {
            setOpdracht(opdracht - 8)
        } else {
            setOpdracht(opdracht + 8)
        }
        setChecked3(!checked3);
    };

    const handleChange4 = () => {
        if (checked4) {
            setOpdracht(opdracht - 16)
        } else {
            setOpdracht(opdracht + 16)
        }
        setChecked4(!checked4);
    };

    const toggleStatus = () => {
        setShowStatus(!showStatus);
    }

    const Checkbox = ({label, value, onChange}) => {
        return (
            <div className={editstyle.showInlineProperty}>
                <input type="checkbox" checked={value} onChange={onChange}/>
                <p>&nbsp;&nbsp;{label}</p>
            </div>
        );
    };

    const getUpdate = (instruction) => {
        setIsInitializing(true)
        // console.log("instruction = " + instruction)
        // e.preventDefault();
        UserService.getUpdate(instruction).then(
            (response) => {
                console.log(response.data)
                // setMynumber(response.data.numberOfRecords)
                // setKey(response.data.requested);
                // setReturnText(response.data.accompanyingText)
                console.log("status = " + response.data.status)
                console.log("timeStamp = " + response.data.timeStamp)
                // setMessage(true)

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setContent2(_content);
            }
        );
    }

    useEffect(() => {
        UserService.getStatus().then(
            (response) => {
                console.log(response.data)
                setUpdateStatusStructure(response.data)
                //expliciet setten bug is axios
                if (response.data.updateActive == true) {
                    setUpdateActive(true)
                } else {
                    setUpdateActive(false)
                    setIsInitializing(false)
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        )
    }, [showStatus]);

    return (
        <div className="headDiv">
            <Helmet>
                <title>Update Datamachine Graph</title>
            </Helmet>
            <h4>Status update: {updateActive || isInitializing ? <span>running</span> : <span>finished</span>}</h4>
            <table className={tableStyle.tablestyle}>
                <thead className={tableStyle.tablestyle}>
                <tr className={tableStyle.tablestyle}>
                    <th className={tableStyle.tablestyle}>
                        <a data-tip data-for="Refresh" data-delay-show='1000' href="#" className={iconstyle.normalButtonActive} onClick={() => toggleStatus()}> <ResfreshIcon/></a>
                        <ReactTooltip id="Refresh" type="error">
                            <span>Refresh table</span>
                        </ReactTooltip>
                    </th>
                    <th>Get records from source</th>
                    <th>Process XML</th>
                    <th>Process webpages</th>
                    <th>Process JSON-LD</th>
                    <th>Process Elastic</th>
                </tr>
                </thead>
                <tbody className={tableStyle.tablestyle}>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Previous update</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.previousStartDate}</td>
                    <td className={tableStyle.tablestyle}></td>
                    <td className={tableStyle.tablestyle}></td>
                    <td className={tableStyle.tablestyle}></td>
                    <td className={tableStyle.tablestyle}></td>
                </tr>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Current update</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.startDate}</td>
                    <td className={tableStyle.tablestyle}></td>
                    <td className={tableStyle.tablestyle}></td>
                    <td className={tableStyle.tablestyle}></td>
                    <td className={tableStyle.tablestyle}></td>
                </tr>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Number of records processed</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.numberOfRecordsStep1}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.numberOfRecordsStep2}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.numberOfRecordsStep3}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.numberOfRecordsStep4}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.numberOfRecordsStep5}</td>
                </tr>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Percentage finished</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.percentageFinishedStep1} %</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.percentageFinishedStep2} %</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.percentageFinishedStep3} %</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.percentageFinishedStep4} %</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.percentageFinishedStep5} %</td>
                </tr>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Total number of records</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.totalNumberOfRecordsStep1}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.totalNumberOfRecordsStep2}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.totalNumberOfRecordsStep3}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.totalNumberOfRecordsStep4}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.totalNumberOfRecordsStep5}</td>
                </tr>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Update status</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.updateStatusStep1}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.updateStatusStep2}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.updateStatusStep3}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.updateStatusStep4}</td>
                    <td className={tableStyle.tablestyle}>{updateStatusStructure.updateStatusStep5}</td>
                </tr>
                <tr className={tableStyle.tablestyle}>
                    <td className={tableStyle.tablestyle}>Use in update</td>
                    <td className={tableStyle.tablestyle}>
                        <Checkbox
                            label=""
                            value={checked0}
                            onChange={handleChange0}
                        />
                    </td>
                    <td className={tableStyle.tablestyle}>
                        <Checkbox
                            label=""
                            value={checked1}
                            onChange={handleChange1}
                        />
                    </td>
                    <td className={tableStyle.tablestyle}>
                        <Checkbox
                            label=""
                            value={checked2}
                            onChange={handleChange2}
                        />
                    </td>
                    <td className={tableStyle.tablestyle}>
                        <Checkbox
                            label=""
                            value={checked3}
                            onChange={handleChange3}
                        />
                    </td>
                    <td className={tableStyle.tablestyle}>
                        <Checkbox
                            label=""
                            value={checked4}
                            onChange={handleChange4}
                        />
                    </td>
                </tr>
                </tbody>
            </table>

            {updateActive || isInitializing ?
                <span>
                        <h6>Update is proces please wait</h6>
                        Last message: {updateStatusStructure.updateStatus}
                    </span> :
                <span>
                    <div className={editstyle.showInlineProperty}>
                        <h6>Run update</h6>
                        <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" className={iconstyle.normalButton} onClick={() => getUpdate(opdracht)}> <DataConnectionIcon/> </a>
                        <ReactTooltip id="SaveIcon" type="error">
                            <span>Start resync indices</span>
                        </ReactTooltip>

                    </div>
                    <p><strong>Message:</strong><br/> {updateStatusStructure.message}</p>
                </span>
            }
        </div>
    );

};

export default GetUpdate



import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router";
import UserService from "../../services/user.service";
import SearchNodeDisplay from "./SearchNodeDisplay";
import iconstyle from "../../css/icons.module.css";
import nodestyle from "../../css/node.module.css"
import {ReactComponent as SearchIcon} from "../Icons/Search.svg";
import {ReactComponent as LessIcon} from "../Icons/chevronL.svg";
import {ReactComponent as MoreIcon} from "../Icons/chevron.svg";
import ReactTooltip from 'react-tooltip';
import AuthService from "../../services/auth.service";
import editStyle from "../../css/edittriple.module.css";

/**
 *
 * @param setHeaderText Text om te gebruiken in een headerbanner
 * @param fieldName Het veld wat gebruikt kan worden om te zoeken op een specifieke property
 * @param type Wat is de Type van de node. Default is het Thing
 * @param link True of False, True: alleen een link teruggeven. False: De node editten
 * @param setLink
 * @param label Niet gebruikt
 * @param placeHolderText De text in het  zoekveld
 * @param size Niet gebruikt
 * @param autoSuggest true of false voor suggestions uit Elastic
 * @param searchOffset Het aantal records per pagina
 * @returns {JSX.Element}
 * @constructor
 */
const GeneralSearch = ({
                           setHeaderText,
                           fieldName,
                           type,
                           link,
                           setLink,
                           label,
                           placeHolderText,
                           size,
                           autoSuggest,
                           searchOffset
                       }) => {
    // Variables
    // const [content, setContent] = useState("");
    // const [mykey, setKey] = useState("");
    const history = useHistory();
    const [mynumber, setMynumber] = useState(0);
    const [myrequested, setRequested] = useState("");
    const [nodes, setNodes] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [query, setQuery] = useState("");
    const [previousQuery, setPreviousQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isInitialMount = useRef(true);
    const [advanced, setAdvanced] = useState(false);
    const [buttonName, setButtonName] = useState("")
    const [showSuggestion, setShowSuggestions] = useState(true)
    // const [headerName, setHeaderName] = useState("")
    const [page, setPage] = useState(0);
    // const [fieldName, setFieldName] = useState("")
    const [typeName, setTypeName] = useState("")
    // const [suggestions, setSuggestions] = React.useState([]);
    // const [myimage, setImage] = useState("");
    const [distinctTypes, setDistinctTypes] = useState([]);
    const [typeTitle, setTypeTitle] = useState("xxxxx");
    const [typeLoaded, setTypeLoaded] = useState(false);
    // Functions

    const logOut = () => {
        AuthService.logout();
        history.push("/");
        window.location.reload();
    };

    const updateSearch = e => {
        setSearchValue(e.target.value);
        setPage(0);
        setPreviousQuery("");
    }

    const handleNextPage = () => {
        setPage(page + 1);
        setQuery(previousQuery);
    }

    const handlePreviousPage = () => {
        setPage(page - 1);
        setQuery(previousQuery);
    }

    // const getSearch = e => {
    //     e.preventDefault();
    //     setQuery(searchValue);
    //     // setSearchValue("");
    // }
    //
    // const cleanSearch = () => {
    //     setSearchValue('')
    // }

    useEffect(() => {
        console.log("TRIGGER Filter on a type")
        if (typeLoaded) {
            setTypeTitle("Filter on a type")
        }

    }, [typeLoaded])

    useEffect(() => {
        setPage(0);
        setPreviousQuery("");
        setMynumber(0);
    }, [searchValue])

    useEffect(() => {
        if (autoSuggest && isInitialMount.current) {
            setShowSuggestions(true)
        }
        if (showSuggestion) {
            setButtonName("Hide suggestions")
        } else {
            setButtonName("Show suggestions")
        }
        setPage(0);
        setPreviousQuery("");
    }, [showSuggestion])

    useEffect(() => {
        if (isInitialMount.current) {
            setHeaderText("Search")
        } else {
            if (isLoading) {
                setHeaderText("Searching...")
            } else {
                if (mynumber > 0) {
                    setHeaderText("Search results")
                    // cleanSearch()
                } else {
                    setHeaderText("Nothing found")
                }
            }
        }
    }, [isLoading]);


    useEffect(() => {
        if (isInitialMount.current) {
            UserService.listDistinctTypes(12).then(
                (response1) => {
                    console.log(response1.data)
                    distinctTypes.push({
                        key: '',
                        value: 'Select a type',
                    })
                    response1.data.forEach((value) => {
                        distinctTypes.push({
                            key: value.type,
                            value: value.type.substring(value.type.lastIndexOf("/") + 1),
                        });
                    });
                    // console.log("distinctTypes.length = " + distinctTypes.length)
                    setTypeLoaded(true)
                },
                (error) => {
                    setPreviousQuery(query);
                }
            )
            isInitialMount.current = false;
        } else {
            if (fieldName !== undefined) {
                fieldName = ""
            }
            if (query !== "") {

                if (typeName.length > 0) {
                    fieldName = "http://schema.org/name"
                }

                setPreviousQuery(query);
                setIsLoading(true);
                UserService.searchRecord(query, typeName, fieldName, page, searchOffset).then(
                    (response) => {
                        console.log(response.data)
                        setNodes(response.data.returnRecord);
                        setMynumber(response.data.numberOfRecords)
                        // setKey(response.data.requested);
                        setRequested(response.data.searchString)
                        console.log("searchString = " + response.data.searchString)
                        console.log("response.data.returnRecord = START")
                        console.log(response.data.returnRecord)
                        console.log("response.data.returnRecord = END")
                        console.log("status = " + response.data.status)
                        console.log("timeStamp = " + response.data.timeStamp)
                        setIsLoading(false);


                        // const data = response.data;
                    },
                    (error) => {
                        setIsLoading(false);
                        if (error.response !== undefined) {
                            const _content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            // console.log("_content: " + _content)
                            // console.log(error.response)
                            // console.log(error.response.data.status)
                            // console.log("error.response.data.message: " + error.response.data.message)
                            // console.log("error.message: " + error.message)
                            if (error.response.data.status === 401) {
                                logOut()
                            }
                        }
                        setNodes([]);
                        setMynumber(0);
                        setRequested(query);
                        setIsLoading(false);
                    }
                );
                setQuery("")
            }
        }
    }, [query]);


    return (
        <div className="firstdiv">
            <div className={iconstyle.showInline}>
                <input className="search" id="inputText1" type="text" value={searchValue} placeholder={placeHolderText}
                       onChange={updateSearch}
                       onKeyPress={(e) => {
                           if (e.key === "Enter" || e.code === "NumpadEnter") {
                               e.preventDefault();
                               setQuery(searchValue)
                           }
                       }
                       }
                />
                <a data-tip data-for="SearchIcon1" data-delay-show='1000' className={iconstyle.suggestionButton}
                   onClick={() => {
                       setQuery(searchValue)
                   }
                   }>&nbsp;&nbsp;<SearchIcon/> </a>&nbsp;&nbsp;
                {mynumber > 0 && <p className={nodestyle.numberofhits}>{nodes.length} hits </p>}&nbsp;&nbsp;
                {page > 0 &&
                    <a className={iconstyle.smallButton} onClick={() => handlePreviousPage()}>
                        <LessIcon/>
                    </a>
                }
                {mynumber > 0 &&
                    <p className={nodestyle.numberofhits}>
                        page {page + 1}
                    </p>
                }
                {searchOffset === nodes.length && mynumber > 0 &&
                    <a className={iconstyle.smallButton} onClick={() => handleNextPage()}>
                        <MoreIcon/>
                    </a>
                }
                <ReactTooltip id="SearchIcon1" type="error">
                    <span>Perform search</span>
                </ReactTooltip>
            </div>
            <p style={{paddingTop: 20, paddingBottom: 10, fontSize: 18, fontWeight: "bold",}}>{typeTitle}</p>
            {distinctTypes.length > 0 &&
                <select
                    className="search"
                    onChange={(e) => {
                        setTypeName(e.target.value)
                        console.log((e.target.value))
                    }}
                >
                    {distinctTypes.map((type, i) => (
                        <option key={i} value={type.key}>{type.value}</option>
                    ))
                    }
                </select>}
            {/*{distinctTypes.map((type,i)=>(*/}
            {/*    <p key={i}>{type}</p>*/}
            {/*))*/}
            {/*}*/}
            <span className={nodestyle.searchResults}>
            {nodes.map(node => (
                <div key={node.recordID}>
                    <SearchNodeDisplay
                        recourCounter={node.recordCounter}
                        recordID={node.recordID}
                        score={node.score}
                        nodeType={node.nodeType}
                        triples={node.returnValues}
                        link={link}
                        setLink={setLink}
                    />
                </div>
            ))}
            </span>
            <div className={iconstyle.showInline}>
                {mynumber > 0 && <p className={nodestyle.numberofhits}>{nodes.length} hits </p>}&nbsp;&nbsp;
                {page > 0 &&
                    <a href="#" className={iconstyle.smallButton} onClick={() => handlePreviousPage()}> <LessIcon/>
                    </a>}
                {mynumber > 0 && <p className={nodestyle.numberofhits}> page {page + 1}</p>}
                {searchOffset === nodes.length && mynumber > 0 &&
                    <a href="#" className={iconstyle.smallButton} onClick={() => handleNextPage()}> <MoreIcon/> </a>}

            </div>
        </div>
    );
};

export default GeneralSearch;


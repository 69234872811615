import styled from 'styled-components';
import * as Constants from './Authentication/Settings/Settings';

export const FooterContainter = styled.div`
    border-color: #28a745;
    border-style: double;
`
export const Filler = styled.div`
    min-height: 25px;

`
export const FooterDiv = styled.div`
    background-color: ${Constants.fontColorDark};
    margin-top: auto;
    //position: fixed;
    //bottom:0;
    //display:block;
    //width:100%
    //z-index: 500
`
export const Box = styled.div`
    //margin-top: auto;
   min-height: 100vh;
    display: flex;
    flex-direction: column;
  //all: revert;
  ////padding: 1em 1em;
  //background: #16224A;
  ////position: bottom;
  ////bottom: 0;
  //width: 100%;
  //flex: 1;
  ////margin-top: -20em;
  //position: absolute;
  //bottom:0;
  ////height: 16em;
  
  
   
  @media (max-width: 1000px) {
    //padding: 2em 3em;
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  width: 100%;
    // max-width: 100em;
    //margin: 0 auto;
    background: #16224A;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  //margin-left: 6em;
`;

export const Row = styled.div`
  padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 
                         minmax(250px, 1fr));
  grid-gap: 20px;
   
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;

export const FooterLink = styled.a`
  color: #D6D7CD;
  margin-bottom: 0.4em;
  font-size: 1.6em;
  text-decoration: none;
   
  &:hover {
      color: #37BEB7;
      transition: 200ms ease-in;
  }
`;

export const Heading = styled.p`
  font-size: 2.4em;
  text-align: center;
  color: #D6D7CD;
  margin-bottom: 1.0em;
  font-weight: bold;
  padding-top: 150px;
  padding-bottom: 15px;
`;

export const SubHeading = styled.p`
  font-size: 1.8em;
  color: #D6D7CD;
  margin-bottom: 0.8em;
  font-weight: bold;
`;

// :root {
//     /*--DatamachineBlue: #4A7DBF;*/
//     --DatamachineBlue: #283d85;
//     --DatamachineOrange: #FFBE54;
//     --DatamachineRed: #8E384E;
//     --DatamachineGreyBlue: #83A5BC;
//     --DatamachineDarkBlue: #16224A;
//     --DatamachineGreenBlue: #37BEB7;
//     --DatamachineBackGroundGrey: #F9F9F6;
//     --DatamachineTextboxGrey: #D6D7CD;
//     --DatamachineTextLogoGrey: #B7B7AA;
//     --DatamachineDarkGrey: #515457;
//     --iconbackgroundcolor: lightgray;
//     --iconbackgroundcolor-accent: blue;
//     --DatamachineTextColor: var(--DatamachineDarkBlue);
//     --text-color: red;
//     --icon-size: 30px;
//     --iconborder: 1px solid var(--DatamachineDarkGrey);
//     --iconborder-radius: 8px;
//     --iconspeed: 500ms;
// }

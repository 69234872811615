import React, {useState} from 'react';
import {

    SearchBlock,
    TextWrapper,
    Heading,
    Subtitle,
    ImgWrapper,
} from './Suggestions.elements';
// import ImageSection from "../Collection/Sections/Image/ImageSection";
import * as Constants from "../../components/Authentication/Settings/Settings";
import * as DOMPurify from "dompurify";
import ShowImage from "../Images/ShowImage";
import {useHistory} from "react-router-dom";


// import {Img, ImgWrapper} from "../InfoSection/InfoSection.elements";

function SuggestionsResultSection(props) {
    const [toggleRefreshData, setToggleRefreshData] = useState(true);
    const history = useHistory();
    let identical = false;

    function gotoNode(id) {
        history.push('/edit/' + id);
        setToggleRefreshData(true);
        window.location.reload();
    }

    // console.log("------------------------>>>>>>>>>>>>>>>>>>SuggestionsResultSection START")
    // console.log("props.node = START" + props.node)
    // console.log( props.node.returnValues)
    // console.log("props.node = END" + props.node)

    let searchResultString = "";
    let score  = "";
    for (let i = 0; i < props.node.returnValues.length; i++) {

        // console.log("++++++++++++++++++++++++++++++ props.node[" + i + "].value = " + props.node.returnValues[i])
        // console.log( props.node.returnValues[i])
        // console.log("++++++++++++++++++++++++++++++ props.node[" + i + "].value = " + props.node.returnValues[i])
        score = props.node.returnValues[i].graph
        if (score === "100,0%"){
            identical = true;
        }
        if (i > 0) {
            searchResultString = searchResultString + "... " + DOMPurify.sanitize(props.node.returnValues[i].value, {ALLOWED_TAGS: []}, {USE_PROFILES: {html: true}}).toString();
        } else {
            searchResultString = DOMPurify.sanitize(props.node.returnValues[i].value, {ALLOWED_TAGS: []}, {USE_PROFILES: {html: true}}).toString();
        }
    }
    if (searchResultString.length > Constants.maxSearchResultString) searchResultString = searchResultString.substring(0, Constants.maxSearchResultString) + "..."
    const searchWords = props.myrequested.split(/\s/).filter(word => word);
    // console.log("searchResultString BEFORE -------->" + searchResultString)
    // console.log("searchResultString BEFORE -------->")
    // searchResultString = DOMPurify.sanitize(searchResultString, {ALLOWED_TAGS: []}, {USE_PROFILES: {html: true}}).toString()
    // console.log("searchResultString AFTER -------->" + searchResultString)
    // console.log("searchResultString AFTER -------->")
    // console.log("------------------------>>>>>>>>>>>>>>>>>>SuggestionsResultSection END")
    return (


        <SearchBlock lightBg={identical} onClick={() => gotoNode(props.topLine)}>

            <ImgWrapper>
                <ShowImage identifier={props.topLine} toggleRefreshData={toggleRefreshData} lowRes={false}
                           fileType={"image/jpeg"} fileUrl={null}/>
            </ImgWrapper>
            <TextWrapper>
                <Subtitle textLeft={true} textFull={true}>Node ID: {props.headline}</Subtitle>
                <Subtitle textLeft={true} textFull={true} dangerouslySetInnerHTML={{__html: searchResultString}}/>
                <Subtitle textLeft={true} textFull={true}>Score = {score}</Subtitle>
            </TextWrapper>
        </SearchBlock>
    );
}

export default SuggestionsResultSection;

import React, {useEffect, useRef, useState} from "react";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
// import ManagementService from "../../services/admin.service"
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import tableStyle from "../../css/table.module.css";
import {isEmail} from "validator";
import EditUser from "../Edit/EditFunctions/EditUser";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as EditUserIcon} from "../Icons/EditUserLight.svg";
import {ReactComponent as AddUserIcon} from "../Icons/AddUser.svg";
import editstyle from "../../css/edittriple.module.css";
import editStyle from "../../css/edittriple.module.css";
import {ReactComponent as SaveIcon} from "../Icons/Save.svg";
import ReactTooltip from "react-tooltip";
import {validPassword} from "../Authentication/Settings/Settings";
import favicon from "../Icons/favicon/favicon.ico";
import {Helmet} from "react-helmet";


const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    if (value.length < 8 || value.length > 100) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 8 and 100 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (!validPassword.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                Password does not meet minimum requirements.
            </div>
        );
    }
};

const ManageUsers = (props) => {
        const currentUser = AuthService.getCurrentUser();
        const [graphBatchRw, setGraphBatchRw] = useState("");

        const [mynumber, setMynumber] = useState(-1);
        const [myrequested, setRequested] = useState("");
        const [nodes, setNodes] = useState([]);
        const [search, setSearch] = useState("");
        const [manageUsers, setManageUsers] = useState(false);
        const [editUser, setEditUser] = useState(false);
        const form = useRef();
        const checkBtn = useRef();

        const [identifier, setIdentifier] = useState("");
        const [username, setUsername] = useState("");
        const [email, setEmail] = useState("");
        const [isActive, setIsActive] = useState(false);
        const [password, setPassword] = useState("");
        const [roles, setRoles] = useState(["Empty"]);
        const [standardUserGraph, setStandardUserGraph] = useState("")
        const [successful, setSuccessful] = useState(false);
        const [message, setMessage] = useState("");
        const [users, setUsers] = useState([{
            identifier,
            username,
            email,
            password,
            standardUserGraph,
            isActive,
            roles
        }
        ]);

        const [updateUser, setUpdateUser] = useState({
            identifier,
            username,
            email,
            password,
            standardUserGraph,
            isActive,
            roles,
        });

        // const [updateUser, setUpdateUser] = useState([]);


        const booleanToString = (b) => {
            return b.toString();
        }

        const onChangeUsername = (e) => {
            const username = e.target.value;
            setUsername(username);
        };

        const onChangeEmail = (e) => {
            const email = e.target.value;
            setEmail(email);
        };

        const onChangePassword = (e) => {
            const password = e.target.value;
            setPassword(password);

        };

        const handleEditUser = (value) => {
            setEditUser(true)
            setUpdateUser(value)

        }

        const printRolesPretty = (roles) => {
            let result = "";
            for (let i = 0; i < roles.length; i++) {
                let n = roles[i].indexOf('_');
                if (i == roles.length - 1) {
                    let tempResult = roles[i].substring(n + 1).replace("_", " ").toLowerCase();
                    tempResult = tempResult.charAt(0).toUpperCase() + tempResult.slice(1);
                    result += tempResult;
                } else {
                    let tempResult = roles[i].substring(n + 1).replace("_", " ").toLowerCase() + ", ";
                    tempResult = tempResult.charAt(0).toUpperCase() + tempResult.slice(1);
                    result += tempResult;
                }

            }
            return result
        }

        const handleRegister = (e) => {
            e.preventDefault();

            setMessage("");
            setSuccessful(false);

            form.current.validateAll();

            if (checkBtn.current.context._errors.length === 0) {
                UserService.registerUser(username, email, password).then(
                    (response) => {
                        setMessage(response.data.message);
                        setSuccessful(true);
                        setUsername("");
                        setEmail("");
                        setPassword("");
                        setManageUsers(!manageUsers);
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setMessage(resMessage);
                        setSuccessful(false);
                    }
                );
            }
        };


        useEffect(() => {
            UserService.userManagement("List", "").then(
                (response) => {
                    console.log("START userManagement")
                    console.log(response.data)
                    // console.log(response.data[0].roles)
                    console.log("START userManagement")


                    // setUsers.name(response.data.name)
                    setUsers(response.data)

                },
                (error) => {

                }
            );
        }, [manageUsers, editUser]);

        return (
            <div className="headDiv">
                <Helmet>
                    <title>Manage Users</title>
                </Helmet>
                <div className="bg-light p-5 rounded-lg m-3">
                    <h2>Manage users</h2>
                </div>

                {editUser ?
                    <EditUser setEditUser={setEditUser} updateUser={updateUser} setUpdateUser={setUpdateUser}/>
                    :
                    <table className={tableStyle.tablestyle}>
                        <thead className={tableStyle.tablestyle}>
                        <tr className={tableStyle.tablestyle}>
                            <th className={tableStyle.tablestyle}>Edit user</th>
                            <th className={tableStyle.tablestyle}>Username</th>
                            <th className={tableStyle.tablestyle}>User Email</th>
                            <th className={tableStyle.tablestyle}>Roles</th>
                            <th className={tableStyle.tablestyle}>Personal graph identifier</th>
                            <th className={tableStyle.tablestyle}>Account active</th>
                        </tr>
                        </thead>
                        <tbody className={tableStyle.tablestyle}>
                        {users.map((user, i) => (
                            <tr key={i}>
                                <td className={tableStyle.tablestyle}><a href="#" className={iconstyle.suggestionButton} onClick={() => handleEditUser(user)}> <EditUserIcon/> </a></td>
                                <td className={tableStyle.tablestyle}>{user.user}</td>
                                <td className={tableStyle.tablestyle}>{user.email}</td>
                                <td className={tableStyle.tablestyle}>{printRolesPretty(user.roles)}</td>
                                <td className={tableStyle.tablestyle}>{user.standardUserGraph}</td>
                                <td className={tableStyle.tablestyle}>{user.isActive ? "True" : "False"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
                {!editUser &&
                <Form onSubmit={handleRegister} ref={form}>
                    <br/><br/>
                    <div className={editstyle.showInlineType}><strong>Add new user</strong></div>
                    <br/><br/>

                    <div className="form-group">
                        <div className={editStyle.parent}>
                            <div className={editStyle.child}>Username</div>
                            <div className={editStyle.child}>
                                <Input
                                    type="text"
                                    style={{width: "60rem"}}
                                    name="username"
                                    value={username}
                                    onChange={onChangeUsername}
                                    validations={[required, vusername]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className={editStyle.parent}>
                            <div className={editStyle.child}>Email</div>
                            <div className={editStyle.child}>
                                <Input
                                    type="text"
                                    style={{width: "60rem"}}
                                    name="email"
                                    value={email}
                                    onChange={onChangeEmail}
                                    validations={[required, validEmail]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className={editStyle.parent}>
                            <div className={editStyle.child}>Password</div>
                            <div className={editStyle.child}>
                                <Input
                                    type="password"
                                    style={{width: "60rem"}}
                                    name="password"
                                    value={password}
                                    onChange={onChangePassword}
                                    validations={[required, vpassword]}
                                />
                                <br/>
                                <p>The password min length is 10 characters, has at least one uppercase character, a number and a special character from '#?!@$%^&*-' to be valid.</p>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <a data-tip data-for="saveUser" data-delay-show='1000' href="#" className={iconstyle.normalButton} onClick={handleRegister}> <AddUserIcon/>
                            <ReactTooltip id="saveUser" type="error">
                                <span>Add user</span>
                            </ReactTooltip>
                        </a>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div
                                className={
                                    successful ? "alert alert-success" : "alert alert-danger"
                                }
                                role="alert"
                            >
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{display: "none"}} ref={checkBtn}/>
                </Form>
                }
            </div>
        );
    }
;

export default ManageUsers;

/**
 * innerNode={innerNode}
 * node={node}
 * updateNode={updateNode}
 * setUpdateNode={setUpdateNode}
 * setIsChanged={setIsChanged}
 */

import React, {useEffect, useState} from "react";
import TextareaAutosize from 'react-textarea-autosize';
import editStyle from "../../../css/edittriple.module.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorState from "draft-js/lib/EditorState";
import { convertToRaw } from 'draft-js';
import {Label, TextBlock} from "./EditFunctionsElements";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "./TextEditor";


function EditText(props) {

    const handleChangeValue = (value) => {
        props.setUpdateNode({...props.updateNode, value: value})
        props.setIsChanged(true)
    }

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }

    const handleChangeLanguageCode = (language) => {
        props.setUpdateNode({...props.updateNode, languageOfValue: language})
        // setLanguageCode({...languageCode, value: language})
        props.setIsChanged(true)
    }

    const populateOptions = (option, key) => {
        return <option key={key} value={option.substring(0, 2)}>{option.substring(",", option.length)}</option>;

    }


    const [checkboxChecked, setCheckboxChecked] = React.useState(false);

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
    };


    // const Editor =({initialData}) =>  {
    //     const [editorState, setEditorState] = useState(() =>
    //         EditorState.createWithContent(
    //             ContentState.createFromBlockArray(
    //                 convertFromHTML('<p>My initial content.</p>')
    //             )
    //         )
    //     );

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(<p>my text</p>))));
    const [text, setText] = useState();
    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        /*let text = blocks.reduce((acc, item) => {
          acc = acc + item.text;
          return acc;
        }, "");*/
        let text = editorState.getCurrentContent().getPlainText("\u0001");
        setText(text);
    };

    return (
        <span>
             <TextBlock isBold={true} isColored={true}>
                <input
                    type="checkbox"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                /> Gebruik de extended editor
            </TextBlock>
            <TextBlock>
                <input name={props.id} type={"radio"} value={"Text"}
                       checked={props.updateNode.valueType === "Text"}
                       onChange={() => handleChangeType("Text")}
                />
                &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            </TextBlock>

            {props.updateNode.valueType === "Text" &&
                <span>
                    {checkboxChecked ?
                        <span>
                            <TextEditor {...props}/>
                        </span>
                        :
                        <span>
                            <Label className={editStyle.editInline}>
                                <TextareaAutosize value={props.updateNode.value} onChange={e => handleChangeValue(e.target.value)} cols={120}/>
                            </Label>
                        </span>
                    }
                    <TextBlock>
                        &nbsp;Language code&nbsp;&nbsp;
                        <select value={props.updateNode.languageOfValue} onChange={e => handleChangeLanguageCode(e.target.value)}>
                            {props.node.languageValues.map((y) => populateOptions(y, y))}
                        </select>
                    </TextBlock>
                </span>
                }
        </span>
    )
}

export default EditText;

import React, {useState, useEffect} from "react";

import editstyle from "../../../css/edittriple.module.css"
import iconstyle from "../../../css/icons.module.css"
import ShowImage from "../../Images/ShowImage";
import {useHistory} from "react-router"
import SelectSubNodes from "../SelectSubNodes";
import {ReactComponent as DeleteIcon} from "../../Icons/Delete.svg";
import {ReactComponent as EmptyIcon} from "../../Icons/EmptyIcon.svg";
import {ReactComponent as ShowMoreIcon} from "../../Icons/ShowMore.svg";
import {ReactComponent as EditIcon} from "../../Icons/Edit.svg";
import {ReactComponent as InsertIcon} from "../../Icons/insertNode.svg";
import {ReactComponent as RemoveIcon} from "../../Icons/removeNode.svg";
import ReactTooltip from 'react-tooltip';
import userService from "../../../services/user.service";


const ViewTripleValues = ({
                              node,
                              RecordKey,
                              toggleRefreshData,
                              setToggleRefreshData,
                              doNotEdit,
                              startEdit,
                              deleteTriple,
                              toSTartEdit,
                              setFileUrl
                          }) => {
// Variables
    const history = useHistory();
    // const [mynumber, setMynumber] = useState("");
    // const [myrequested, setRequested] = useState("");
    const [showIri, setShowIri] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    // const [nodes, setNodes] = useState([]);

    function gotoNode(id) {
        history.push('/edit/' + id);
        setToggleRefreshData(true);
        window.location.reload();
    }

    const toggleIri = () => {
        setShowIri(!showIri)
    }

    const manageSchemaRole = (triple, instruction) => {
        console.log(triple.identifier);
        console.log(triple.fieldName);
        console.log(triple.value);
        userService.manageSchemaRole(triple.identifier, triple.fieldName, triple.value, instruction).then(
            (response) => {
                console.log("START manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole")
                console.log(response.data)
                if (response.data.status === "404"){
                    history.push("/");
                }
                console.log("searchString = " + response.data.searchString)
                console.log("status = " + response.data.status)
                console.log("timeStamp = " + response.data.timeStamp)
                setIsLoading(false);
                setToggleRefreshData(!toggleRefreshData)
                console.log("END manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole manageSchemaRole")
                setShowIri(!showIri)
            },
            (error) => {
                setIsLoading(false);
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setToggleRefreshData(!toggleRefreshData)
            }
        );

    }

    const setTheFileUrl = (fileUrl) => {
        setFileUrl(fileUrl)
    }


    return (
        <span>
            {node.fieldName === "rdf:type" ?
                <div className={editstyle.showInlineType}>
                    {(!doNotEdit && !startEdit) ?
                        <a href="#" className={iconstyle.suggestionButton} onClick={() => deleteTriple()}> <DeleteIcon/></a>
                        :
                        <a data-tip data-for="AutoSuggest" className={iconstyle.suggestionFiller}><EmptyIcon/></a>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Type:</strong>&nbsp;&nbsp;&nbsp;&nbsp;{node.value}
                </div>
                :
                <div className={editstyle.showInlineProperty}>
                    {(!doNotEdit && !startEdit) ?
                        <a className={iconstyle.suggestionButton} onClick={() => toSTartEdit()}> <EditIcon/></a>
                        :
                        <a className={iconstyle.suggestionFiller}><EmptyIcon/></a>
                    }
                    {(!doNotEdit && !startEdit) ?
                        <a href="#" className={iconstyle.suggestionButton} onClick={() => deleteTriple()}> <DeleteIcon/></a>
                        :
                        <a className={iconstyle.suggestionFiller}><EmptyIcon/></a>
                    }
                    {(node.internalLink) ?
                        <a className={iconstyle.suggestionButton} onClick={() => toggleIri()}> <ShowMoreIcon/></a>
                        :
                        <a className={iconstyle.suggestionFiller}><EmptyIcon/></a>
                    }
                    {(node.roleStatus) ?
                        <span>
                            {(node.roleStatus === 1) ?
                                <a className={iconstyle.suggestionButton} onClick={() => manageSchemaRole(node, "insert")}><InsertIcon/></a>
                                :
                                <a className={iconstyle.suggestionButton} onClick={() => manageSchemaRole(node, "remove")}><RemoveIcon/></a>
                            }
                        </span>
                        :
                        <a className={iconstyle.suggestionFiller}><EmptyIcon/></a>
                    }

                    <>{node.fieldName.endsWith("http://schema.org/contentUrl") && <>{setTheFileUrl(node.value)} </>}</>
                    <p><strong>Fieldname:&nbsp;&nbsp;</strong></p>
                    <p className={editstyle.fieldname}>{node.fieldName}</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <p className={editstyle.showInlineProperty}><strong>Value:&nbsp;&nbsp;</strong></p>
                    <div>
                        {node.valueType !== "iri" && <span>{node.value}</span>}
                        {node.internalLink &&
                            <a onClick={() => gotoNode(node.value)} className={editstyle.clickable}>{node.value}</a>}
                        {!node.internalLink && node.valueType === "iri" &&
                            <a href={node.value} target="_blank" className={editstyle.clickable}>{node.value}</a>}

                    </div>
                    <span>{showIri && <SelectSubNodes identifier={node.value} onlyValues={true}
                                                      setToggleRefreshData={setToggleRefreshData}
                                                      setIsLoading={setIsLoading} level={0}/>}</span>
                    {node.languageOfValue &&
                        <p>&nbsp;&nbsp;&nbsp;<strong>Language:&nbsp;&nbsp;</strong> {node.languageOfValue}</p>}

                </div>
            }
            </span>
    );
};
export default ViewTripleValues;


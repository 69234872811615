// isBC={isBC} setIsBC={setIsBC} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged}
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import React, {useState} from "react";
import editStyle from "../../../css/edittriple.module.css";

function EditDateTime(props) {
    const [dateTimeState, setDateTimeState] = useState(new Date())

    const handleBC = (check) => {
        props.setIsBC(check)
    }

    const changeDateTime = (e) => {
        setDateTimeState(e)
        let myDate = moment(e).format('YYYY-MM-DD hh:mm:ss')
        if (props.isBC) {
            myDate = myDate + " BC"
        } else {
            myDate = myDate + " AD"
        }
        props.setUpdateNode({...props.updateNode, value: myDate, valueType: "DateTime"})
        props.setIsChanged(true)
    }

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }
    return (
        <label className={editStyle.editInline}>
            <input name={props.id} type={"radio"} value={"DateTime"}
                   checked={props.updateNode.valueType === "DateTime"}
                   onChange={() => handleChangeType("DateTime")}/>
            &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            <br/>
            {props.updateNode.valueType === "DateTime" &&
            <>
                Selected
                date: {moment(dateTimeState).format('MMMM Do YYYY HH:mm')}&nbsp;&nbsp;
                <input type={"checkbox"} checked={props.isBC}
                       onChange={() => handleBC(!props.isBC)}/>
                &nbsp;BC&nbsp;&nbsp;
                <DateTimePicker
                    onChange={changeDateTime}
                    value={dateTimeState}
                    isOpen={true}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    calendarType='ISO 8601'
                />
            </>
            }
        </label>
    )
}
export default EditDateTime

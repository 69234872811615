import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router"
import TypesSelector from "./TypesSelector";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import {Helmet} from "react-helmet";

const NewNode = () => {
// Variables
    const history = useHistory();
    const isInitialMount = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [recordID, setRecordID] = useState("");
    const [addType, setAddType] = useState(false);
    const [toggleRefreshData, setToggleRefreshData] = useState(false);

    function editNode(id) {
        history.push('/edit/' + id);
        window.location.reload();
    }

    const logOut = () => {
        AuthService.logout();
        history.push("/");
        window.location.reload();
    };

    // getNewIdentifier
    useEffect(() => {
        setIsLoading(true);
        UserService.getNewIdentifier().then(
            (response) => {
                console.log("NewNode NewNode")
                console.log(response.data)
                if (response.data.status === 200){
                    setRecordID(response.data.recordID)
                    setAddType(true)
                }
                setIsLoading(false);
            }
            ,
            (error) => {
                setIsLoading(false);
                if (error.response.data.status === 401) {
                    logOut()
                }
            }
        );
    }, []);

    useEffect(()=>{
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            editNode(recordID);
        }
    },[toggleRefreshData]);


    return (
        <div className="headDiv">
            <Helmet>
                <title>Create New Node</title>
            </Helmet>
            <div className="bg-light p-5 rounded-lg m-3">
                <h3>Insert new node {isLoading && <>Loading...</>}</h3>
                <h4><strong>Node ID</strong> {recordID}</h4>
            </div>
            {addType && <TypesSelector recordID={recordID}
                                       setAddType={setAddType} toggleRefreshData={toggleRefreshData}
                                       setToggleRefreshData={setToggleRefreshData}/>
            }
        </div>
    );
};

export default NewNode

import React, {useEffect, useRef, useState} from "react";

import 'react-calendar/dist/Calendar.css';
import UserService from "../../services/user.service";
import Update from "./TripleFunctions/Update";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as CancelIcon} from "../Icons/Cancel.svg";
import editstyle from "../../css/edittriple.module.css";
import {ReactComponent as CheckIcon} from "../Icons/Check.svg";

/**
 *
 * @param mytriple
 * @param toStartEdit
 * @param setIsChanged
 * @param setToggleRefreshData
 * @param instructionType update, insert, delete, addType
 * @returns {JSX.Element}
 * @constructor
 */
const TypeDelete = ({mytriple, toStartEdit, setIsChanged, setToggleRefreshData, instructionType}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [alsoDeleteTriples, setAlsoDeleteTriples] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [triplesToDeleteAlso, setTriplesToDeleteAlso] = useState([]);
    // const [nodes, setNodes] = useState([]);
    // const [isBC, setIsBC] = useState(false);
    const [doToggleSave, setDoToggleSave] = useState(false);
    // const [languageCode, setLanguageCode] = useState("");
    const [updateNode, setUpdateNode] = useState({
        identifier: "",
        fieldName: "",
        value: "",
        valueType: "",
        languageOfValue: "",
        graph: "",
        doNotEdit: false,
        internalLink: false,
        imageObject: false
    });
    // const x = Math.random();
    const isInitialMount = useRef(true);

    // https://github.com/wojtekmaj/react-calendar
    // const [dateState, setDateState] = useState(new Date())
    // const [dateTimeState, setDateTimeState] = useState(new Date())
    // const [timeState, setTimeState] = useState(moment(Date.now()).format('HH:mm'))

    const cancelAction = () => {
        console.log("hier: ")
        console.log(updateNode)
        setUpdateNode({...updateNode, value: "", valueType: ""})
        setIsChanged(false)
        toStartEdit(false)
    }

    useEffect(() => {
        if (isSuccess) {
            setToggleRefreshData(true)
            cancelAction()
        } else {
            setIsChanged(false)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            Update({mytriple, updateNode, instructionType, setIsLoading, setIsSuccess})
        }
    }, [doToggleSave]);


    // Hierkomen we binnen
    useEffect(() => {
        UserService.getUniqueProperties(mytriple.identifier, mytriple.value).then(
            (response) => {
                console.log("TypeDelete->getUniqueProperties: ")
                console.log(response.data)
                setTriplesToDeleteAlso(response.data.returnRecord[0].returnValues)
            },
            (error) => {

            }
        )
    }, [])


    return (
        <span>{isLoading && <>Updating Please Wait...</>}
            <div>
                {console.log("&&&&&&&&&&&&&&&&&%%%%%%%%%%%%%%$$$$$$$$$$$$$$###############")}
                {console.log("isLoading = " + isLoading)}
                {console.log("alsoDeleteTriples = " + alsoDeleteTriples)}
                {console.log("triplesToDeleteAlso = " + triplesToDeleteAlso.length)}
                <h4>Confirm that you want to delete type "{mytriple.value}"</h4>
                {triplesToDeleteAlso.length > 0 &&
                <>
                    <h6>These Triples will be deleted too:</h6>
                    <ol>
                        {triplesToDeleteAlso.map(deleteNodes => (
                            <li>
                                <p>{deleteNodes.fieldName}</p>
                            </li>
                        ))
                        }
                    </ol>
                </>
                }
                {console.log("Confirm that you want to delete this")}
                {console.log(alsoDeleteTriples)}
                {console.log(triplesToDeleteAlso)}
                {console.log("Confirm that you want to delete")}
                <div className={editstyle.showInlineProperty}>
                     <a href="#" className={iconstyle.suggestionButton} onClick={() => setDoToggleSave(!doToggleSave)}> <CheckIcon/> </a>
                    &nbsp;&nbsp;
                    <a href="#" className={iconstyle.suggestionButton} onClick={() => cancelAction()}> <CancelIcon/> </a>
                </div>
            </div>

        </span>
    );
};

export default TypeDelete;

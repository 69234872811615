import React, {useEffect, useRef, useState} from "react";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import editStyle from "../../../css/edittriple.module.css";
import iconStyle from "../../../css/icons.module.css"
import {validPassword} from "../../Authentication/Settings/Settings";
import {ReactComponent as SaveIcon} from "../../Icons/Save.svg";
import {ReactComponent as CancelIcon} from "../../Icons/Cancel.svg";
import iconstyle from "../../../css/icons.module.css";
import {ReactComponent as EditIcon} from "../../Icons/Edit.svg";
import ReactTooltip from "react-tooltip";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (value != null) {
        if (!isEmail(value) && value.length > 0) {
            return (
                <div className="alert alert-danger" role="alert">
                    This is not a valid email.
                </div>
            );
        }
    }
};

const validName = (value) => {
    if (value.length < 3) {
        return (
            <div className="alert alert-danger" role="alert">
                The minimum length is 3.
            </div>
        );
    }
};

const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (!validPassword.test(value) && value.length > 0) {
        return (
            <div className="alert alert-danger" role="alert">
                The password min length is 10 characters, has at least one uppercase character, a number and a special character from '#?!@$%^&*-' to be valid.
            </div>
        )
    }
};

function EditUser(props) {
    const currentUser = AuthService.getCurrentUser();
    const [graphBatchRw, setGraphBatchRw] = useState("");

    const [mynumber, setMynumber] = useState(-1);
    const [myrequested, setRequested] = useState("");
    const [nodes, setNodes] = useState([]);
    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([]);
    const [manageUsers, setManageUsers] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [password, setPassword] = useState("");
    const [standardUserGraph, setStandardUserGraph] = useState("")
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const [roles, setRoles] = useState([]);

    const booleanToString = (b) => {
        return b.toString();
    }

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const handleCancel = () => {
        props.setEditUser(false);
    }

    const onChangeisActive = (value) => {
        props.setUpdateUser({...props.updateUser, isActive: value})
    }

    const onChangeEmail = (e) => {
        const email = e.target.value;
        props.setUpdateUser({...props.updateUser, email: email})
    };

    const onChangestandardUserGraph = (e) => {
        const input = e.target.value;
        props.setUpdateUser({...props.updateUser, standardUserGraph: input})
    };

    const onChangesPassword = (e) => {
        const input = e.target.value;
        props.setUpdateUser({...props.updateUser, password: input})
    };

    const handleUserEdit = (e) => {
        e.preventDefault();
        setMessage("");
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            UserService.userManagement("Update", props.updateUser).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                    props.setEditUser(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }
    };


    useEffect(() => {
        const graphEnd = props.updateUser.standardUserGraph.substr(props.updateUser.standardUserGraph.lastIndexOf("/") + 1)
        setStandardUserGraph(props.updateUser.standardUserGraph.substring(0, props.updateUser.standardUserGraph.lastIndexOf("/") + 1))
        props.setUpdateUser({...props.updateUser, standardUserGraph: graphEnd})
    }, []);

    return (
        <div className="headDiv">

            <Form onSubmit={handleUserEdit} ref={form}>
                <div className={editStyle.parent}>
                    <div className={editStyle.child}><strong>Edit user:</strong></div>
                    <div className={editStyle.child}>{props.updateUser.user} </div>
                </div>
                <br/>
                <div className="form-group">
                    <div className={editStyle.parent}>
                        <div className={editStyle.child}><strong>Email:</strong></div>
                        <div className={editStyle.child}>
                            <Input
                                type="text"
                                name="email"
                                style={{width: "60rem"}}
                                value={props.updateUser.email}
                                onChange={onChangeEmail}
                                validations={[validEmail]}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className={editStyle.parent}>
                        <div className={editStyle.child}><strong>User graph:</strong></div>
                        <div className={editStyle.child}>{standardUserGraph}</div>
                        <div className={editStyle.child}>
                            <Input
                                type="text"
                                className={editStyle.child}
                                name="standardUserGraph"
                                style={{width: "33rem"}}
                                value={props.updateUser.standardUserGraph}
                                onChange={onChangestandardUserGraph}
                                validations={[validName]}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className={editStyle.parent}>
                        <div className={editStyle.child}><strong>Password:</strong></div>
                        <div className={editStyle.child}>
                            <Input
                                // type="password"
                                type="text"
                                className={editStyle.child}
                                name="password"
                                value={props.updateUser.password}
                                onChange={onChangesPassword}
                                validations={[vpassword]}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className={editStyle.parent}>
                        <div className={editStyle.child}><strong>User Roles:</strong></div>
                    </div>
                </div>

                <div className="form-group">
                    <div className={editStyle.parent}>
                        <div className={editStyle.child}><strong>User is:</strong></div>
                        <div className={editStyle.child}>
                            <select
                                className={editStyle.child}
                                name="isActive"
                                value={props.updateUser.isActive}
                                onChange={e => onChangeisActive(e.target.value)}
                            >
                                <option value={true}>true</option>
                                <option value={false}>false</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className={editStyle.showInlineProperty}>

                        <a data-tip data-for="saveUser" data-delay-show='1000' href="#" className={iconstyle.normalButton} onClick={handleUserEdit}> <SaveIcon/>
                            <ReactTooltip id="saveUser" type="error">
                                <span>Save user</span>
                            </ReactTooltip>
                        </a>
                        <a data-tip data-for="cancelUser" data-delay-show='1000' href="#" className={iconstyle.normalButton} onClick={handleCancel}> <CancelIcon/>
                            <ReactTooltip id="cancelUser" type="error">
                                <span>Cancel edit user</span>
                            </ReactTooltip>
                        </a>
                    </div>
                </div>

                {message && (
                    <div className="form-group">
                        <div
                            className={
                                successful ? "alert alert-success" : "alert alert-danger"
                            }
                            role="alert"
                        >
                            {message}
                        </div>
                    </div>
                )}
                <CheckButton style={{display: "none"}} ref={checkBtn}/>
            </Form>
        </div>
    );
};

export default EditUser;

import React, {useState, useEffect} from "react";

import {FlexGrid, SearchResult, H2Found, SuggestButton, FlexButton} from "./Suggestions.elements";
import {useHistory} from "react-router-dom";
import UserService from "../../services/user.service";
import * as Constants from "../../components/Authentication/Settings/Settings";
import SuggestionsResultSection from "./SuggestionsResultSection";

function Suggestions(props) {
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(25);
    const [myRequested, setMyRequested] = useState("");
    const [query, setQuery] = useState(sessionStorage.getItem("query") || "");
    const [previousQuery, setPreviousQuery] = useState("");
    const [doReloadQuery, setDoReloadQuery] = useState(props.reloadQuery)
    const [enableLeft, setEnableLeft] = useState(false);
    const [enableRight, setEnableRight] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [returnHeader, setReturnHeader] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [showButton, setShowButton] = useState(true);
    const [extraText, setExtraText] = useState("");
    const [numberOfRecords, setNumberOfRecords] = useState(0);

    function showMore() {
        setOffset((page + 1) * 25)
        setPage(page + 1)
        setQuery(previousQuery);
        setDoReloadQuery(!doReloadQuery)
        // alert('You clicked me!');
    }

    const handleNextPage = () => {
        if (enableRight) {
            setPage(page + 1);
            setQuery(previousQuery);
            setDoReloadQuery(true)
        }
    }

    const handlePreviousPage = () => {
        if (enableLeft) {
            setPage(page - 1);
            setQuery(previousQuery);
            setDoReloadQuery(true)
        }
    }

    useEffect(() => {
        UserService.listSuggestions(props.imageArray.toString(), 0, offset,"false").then(
            (response) => {
                setIsLoading(true);
                setReturnHeader(response.data.returnRecord);
                setNodes(response.data.returnRecord);
                setNumberOfRecords(response.data.numberOfRecords);
                if (offset > response.data.numberOfRecords || !response.data.returnRecord.length > 0) {
                    setShowButton(false)
                }
                if (response.data.numberOfRecords === 0) {
                    setExtraText(response.data.accompanyingText)
                    console.log("response.data.accompanyingText === \"Image recognition is currently running\" = " + (response.data.accompanyingText === "Image recognition is currently running").toString())
                    if (response.data.accompanyingText === "Image recognition is currently running"){
                        console.log("---------------------------props.setBusyMatching(true)")
                        props.setBusyMatching(true)
                        props.setToggleRefreshData(true)
                    }
                    else{
                        props.setBusyMatching(false)
                    }
                }

                console.log("Suggestions->START->Suggestions->START->Suggestions->START->Suggestions->START->Suggestions->START->Suggestions->START->")
                console.log("response.data = ")
                console.log(response.data)
                console.log(response.data.numberOfRecords)
                console.log("response.data.returnRecord.length = " + response.data.returnRecord.length)
                console.log("Page  = " + page + ", offset = " + offset)
                console.log(props.imageArray.toString())
                console.log(response.data.returnRecord)
                console.log("props.rdfPrefix[0] = " + props.rdfPrefix.toString())
                console.log("Suggestions->END->Suggestions->END->Suggestions->END->Suggestions->END->Suggestions->END->Suggestions->END->")
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }, [doReloadQuery]);


    return (
        <span>

            {numberOfRecords > 0 ?
                <H2Found lightTextDesc={false}>Vergelijkbare foto's</H2Found>
                :
                <H2Found lightTextDesc={false}>{extraText}</H2Found>
            }
            {showButton &&
                <FlexButton>
                    <SuggestButton onClick={showMore}>Show More</SuggestButton>
                </FlexButton>
            }
            <SearchResult>
                <FlexGrid>
                    {nodes.map((node, i) =>
                        <SuggestionsResultSection key={i}
                                                  topLine={props.rdfPrefix[0] + ":" + node.recordID}
                                                  headline={node.recordID}
                                                  node={node}
                                                  myrequested={myRequested}
                                                  lightBg={(i % 2)}
                                                  lightText={!(i % 2)}
                                                  lightTopLine={!(i % 2)}
                                                  lightTextDesc={!(i % 2)}
                        />
                    )}
                </FlexGrid>
            </SearchResult>
            {showButton &&
                <FlexButton>
                    <SuggestButton onClick={showMore}>Show More</SuggestButton>
                </FlexButton>
            }
        </span>
    );
}

export default Suggestions
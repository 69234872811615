import React, {useEffect, useState} from "react";
import UserService from "../../services/user.service";
import imageStyle from "../../css/showimage.module.css";
import {base64StringToBlob} from "blob-util";
import {Document, Page} from 'react-pdf';
import {pdfjs} from 'react-pdf';
import {ReactComponent as LessIcon} from "../Icons/chevronL.svg";
import {ReactComponent as MoreIcon} from "../Icons/chevron.svg";
import iconstyle from "../../css/icons.module.css";
import contains from "validator/es/lib/contains";
import ReactPlayer from "react-player";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ShowImage = ({identifier, toggleRefreshData, nodeId, lowRes, fileType, fileUrl}) => {
    const [myImage, setImage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfPage, setPdfPage] = useState();
    const [typeOfFile, setTypeOfFile] = useState("")
    // const [isLowRes, setIsLowRes] = useState(true);
    const [isLowRes, setIsLowRes] = useState(lowRes);
    const [imageWidth, setImageWidth] = useState("0")
    const [imageHeight, setImageHeight] = useState("0")
    // const getSearch = e => {
    //     e.preventDefault();
    //     console.log("getSearch: " + search)
    //     setQuery(search);
    //     setSearch('');
    // }

    // useEffect(() =>{
    //     setSearch({recordID});
    // },[])

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };


    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    const setPdfPageNumber = (myNumber) => {
        if (pageNumber + myNumber > 0 && pageNumber + myNumber <= numPages) {
            setPageNumber(pageNumber + myNumber);
        }
    }

    const handleShowDialog = () => {
        // console.log("I pass dialog before")
        if (!isLowRes) {
            setIsOpen(!isOpen);
            // console.log("clicked");
        } else {
            setIsLowRes(false)
            setIsOpen(!isOpen);
        }
        // console.log("I pass dialog after")
    };

    useEffect(() => {
        // console.log("I pass lowRes before")
        setIsLowRes(lowRes);
        // console.log("I pass lowRes after")
    }, []);

    useEffect(() => {
        // console.log("I pass loading before")
        // console.log("fileUrl = " + fileUrl)
        // console.log("identifier = " + identifier)
        // console.log("fileType1 = " + fileType)
        if (fileUrl === null) {
            // console.log("I pass loading fileUrl === null")
            UserService.getImage(identifier, isLowRes, nodeId, fileType).then(
                (response) => {
                    setImage(URL.createObjectURL(base64StringToBlob(response.data.mediaContent, response.data.mediaExtention)));
                    setTypeOfFile(response.data.mediaExtention);
                    setImageWidth(response.data.width)
                    setImageHeight(response.data.height)
                    // setPdfPage(URL.createObjectURL(new base64StringToBlob(response.data.mediaContent, response.data.mediaExtention)))
                    // console.log("getImage->query = " + identifier);
                    // console.log("getImage->response.data.mediaExtention = " + response.data.mediaExtention);
                    // console.log("response.header: ")
                    // console.log(response.headers);
                }, (error) => {
                    // console.log("Error retrieving config 0")
                }
            );
        }
        // console.log("I pass loading after")
    }, [toggleRefreshData, isLowRes])

    return (
        <span>
            {typeOfFile.startsWith("image") && myImage != null &&
                <div>
                    <img
                        className={imageStyle.showimage}
                        src={myImage}
                        onClick={() => {
                            handleShowDialog()
                        }}/>
                    {!lowRes && <span><br/>&nbsp;&nbsp;&nbsp;&nbsp;{typeOfFile} {imageWidth} X {imageHeight}</span>}
                    {isOpen && (
                        <dialog
                            className={imageStyle.dialog}
                            style={{position: "realtive"}}
                            open
                            onClick={() => {
                                handleShowDialog()
                            }}
                        >
                            <img
                                className={imageStyle.image}
                                src={myImage}
                                onClick={() => {
                                    handleShowDialog()
                                }}
                                alt="no image"
                            />
                        </dialog>
                    )}
                </div>
            }
            {fileType.includes("PDF") && myImage != null &&
                <div>
                    <p>
                        {pageNumber > 1 &&
                            <a className={iconstyle.smallButton} onClick={() => setPdfPageNumber(-1)}> <LessIcon/>
                            </a>}&nbsp;
                        Page {pageNumber} of {numPages}&nbsp;
                        {pageNumber < numPages &&
                            <a className={iconstyle.smallButton} onClick={() => setPdfPageNumber(1)}> <MoreIcon/> </a>}
                    </p><br/>
                    <Document file={myImage} onLoadSuccess={onDocumentLoadSuccess} options={options}
                              loading={'Please wait, the document is loading!'}>
                        <Page pageNumber={pageNumber}/>
                    </Document>
                </div>
            }
            {typeOfFile.endsWith("mp3") && myImage != null &&
                <div>
                    <ReactPlayer
                        playing
                        className='react-player'
                        url={myImage}
                        width='40.0rem'
                        height='2.5rem'
                        playsinline={true}
                        pip={false}
                        controls={true}
                    />

                </div>
            }
            {fileType.endsWith("mp4") && fileUrl != null &&
                <div>
                    <ReactPlayer
                        playing
                        className='react-player'
                        url={fileUrl}
                        width='80.0rem'
                        playsinline={true}
                        pip={false}
                        controls={true}
                    />

                </div>
            }

    </span>
    );

};

export default ShowImage;

import styled from 'styled-components';

export const TextBlock = styled.div`
    color: ${({ isColored }) => (isColored ? '#16224A' : '#515457')};
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.4rem;
    font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
    
`

export const Label = styled.label`
    color: ${({ lightTopLine }) => (lightTopLine ? '#16224A' : '#515457')};
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.4rem;
    font-weight: ${({ isBold }) => (isBold ? '700' : '300')};
    
`



// innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged}
import moment from "moment";
import TimePicker from "react-time-picker";
import React, {useState} from "react";
import editStyle from "../../../css/edittriple.module.css";

function EditTime(props) {

    const [timeState, setTimeState] = useState(moment(Date.now()).format('HH:mm'))

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }

    const changeTime = (e) => {
        setTimeState(e)
        props.setUpdateNode({...props.updateNode, value: e, valueType: "Time"})
        props.setIsChanged(true)
    }

    return (
        <label className={editStyle.editInline}>
            <input name={props.id} type={"radio"} value={"Time"} checked={props.updateNode.valueType === "Time"} onChange={() => handleChangeType("Time")}/>
            &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            {props.updateNode.valueType === "Time" &&
            <>
                Selected
                date: {moment(timeState).format('HH:mm')}&nbsp;&nbsp;
                <TimePicker
                    onChange={changeTime}
                    value={timeState}
                    isOpen={true}
                />
            </>
            }
        </label>
    )
}

export default EditTime

import React, {useEffect, useState} from "react";
import objectHash from "object-hash";
import ShowImage from "../Images/ShowImage";
import ReturnValueEdit from "./ReturnValueEdit";
import PropertiesSelector from "./PropertiesSelector";
import TypesSelector from "./TypesSelector";
import UploadFiles from "../Upload/UploadFiles"
import toolbarstyle from "../../css/toolbar.module.css";
import editstyle from "../../css/edittriple.module.css";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as AddTripleIcon} from "../Icons/AddTriple.svg";
import {ReactComponent as AddImageIcon} from "../Icons/AddImage.svg";
import {ReactComponent as DeleteImageIcon} from "../Icons/DeleteImage.svg";
import {ReactComponent as DeleteImage2Icon} from "../Icons/DeleteImage2.svg";
import {ReactComponent as ShowSubNodesIcon} from "../Icons/ShowSubNodes.svg";
import {ReactComponent as ShowSubNodesSelectedIcon} from "../Icons/ShowSubNodesSelected.svg";
import {ReactComponent as AddPredicateIcon} from "../Icons/AddPredicate.svg";
import {ReactComponent as AddPredicateSelectedIcon} from "../Icons/AddPredicateSelected.svg";
import {ReactComponent as PdfFileIcon} from "../Icons/pdf_file_icon.svg"
import {ReactComponent as AddVideoIcon} from "../Icons/AddVideo.svg"
import {ReactComponent as AddMusicIcon} from "../Icons/AddMusic.svg"
import ReactTooltip from 'react-tooltip';
import TripleDelete from "./TripleDelete";

const ReturnRecordEdit = ({
                              recordCounter,
                              recordID,
                              score,
                              nodeType,
                              triples,
                              toggleRefreshData,
                              setToggleRefreshData,
                              showSubNodes,
                              handleShowSubNodes,
                              hasImage,
                              contentUrlImageTriple,
                          }) => {
    const [query, setQuery] = useState("");
    const [showItem, setShowItem] = useState(false);
    const [addTriple, setAddTriple] = useState(false);
    const [addTypeStep1, setAddTypeStep1] = useState(false);
    const [addType, setAddType] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [attachFile, setAttachFile] = useState(false);
    const [isImageFile, setIsImageFile] = useState(false);
    const [isPdfFile, setIsPdfFile] = useState(false);
    const [isVideoFile, setIsVideoFile] = useState(false);
    const [isAudioFile, setIsAudioFile] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [doDeleteImage,setDoDeleteImage]=useState(false);


    const handleAddTriple = () => {
        setAddTriple(!addTriple)
    }

    const handleAddType = () => {
        setAddType(!addType)
    }

    const toStartEdit = () => {
        // this.setInstructionType("update")
        // this.setState(prevState => {
        //     return {startEdit: !prevState.startEdit}
        // })
        setDoDeleteImage(false);
    }

    const setIsChanged = (newValue) => {
        // this.setState(() => {
        //     return {isChanged: newValue}
        // })
        // setDoDeleteImage(false);
        setToggleRefreshData(!toggleRefreshData)
    }


    const deleteImage = (toggle) => {
        setDoDeleteImage(toggle)
        {console.log("=======================================================================")}
        {console.log("contentUrlImageTriple")}
        {console.log(contentUrlImageTriple)}
        {console.log("=======================================================================")}
    }

    const hashTriple = (triple) => {
        return objectHash(triple)
    }

    // useEffect(() => {
    //     switch (nodeType) {
    //         case ("3DModel"):
    //         case ("DataDownload"):
    //         case ("Barcode"):
    //         case ("LegislationObject"):
    //         case ("MediaObject"):
    //             setIsImageFile(true);
    //             break;
    //     }
    // }, [])

    useEffect(() => {
        console.log("Nodetype = " + nodeType)
        switch (nodeType) {
            case ("ImageObject"):
            case ("ImageObjectSnapshot"):
                setIsImageFile(true);
                break;
            case ("MediaObject"):
                setIsPdfFile(true);
                console.log("PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+PDF+")
                break;
            case ("MusicVideoObject"):
            case ("VideoObject"):
            case ("VideoObjectSnapshot"):
                setIsVideoFile(true);
                console.log("Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+Video+")
                break;
            case ("AudioObjectSnapshot"):
            case ("AudioObject"):
            case ("Audiobook"):
                setIsAudioFile(true);
                break;
        }
    }, [])


    return (
        <span>
            <nav className={toolbarstyle.navbar2}>
                <ul className={toolbarstyle.navbarNavLeft}>
                     {addType ? <a data-tip data-delay-show='1000' data-for="AddPredicateSelectedIcon" href="#"
                                   className={iconstyle.suggestionButtonActive} onClick={() => {
                             handleAddType()
                         }}> <AddPredicateSelectedIcon/> <ReactTooltip id="AddPredicateSelectedIcon" type="error"
                                                                       place="bottom">
                             <span>Stop adding a type to the node</span>
                         </ReactTooltip></a>
                         :
                         <a data-tip data-delay-show='1000' data-for="AddPredicateIcon" href="#"
                            className={iconstyle.suggestionButton} onClick={() => {
                             handleAddType()
                         }}> <AddPredicateIcon/> <ReactTooltip id="AddPredicateIcon" type="error" place="bottom">
                             <span>Add a type to the node</span>
                         </ReactTooltip>
                         </a>
                     }
                    {addTriple ? <a data-tip data-delay-show='1000' data-for="AddTripleIcon" href="#"
                                    className={iconstyle.suggestionButtonActive} onClick={() => {
                            handleAddTriple()
                        }}> <AddTripleIcon/> <ReactTooltip id="AddTripleIcon" type="error" place="bottom">
                            <span>Stop adding a property to the node</span>
                        </ReactTooltip></a>
                        :
                        <a data-tip data-delay-show='1000' data-for="AddTripleIcon" href="#"
                           className={iconstyle.suggestionButton} onClick={() => {
                            handleAddTriple()
                        }}> <AddTripleIcon/>
                            <ReactTooltip id="AddTripleIcon" type="error" place="bottom">
                                <span>Add a property to the node</span>
                            </ReactTooltip>
                        </a>

                    }

                    {isVideoFile && <span>
                        {
                            attachFile ? <a data-tip data-delay-show='1000' data-for="AddVideoIcon" href="#"
                                            className={iconstyle.suggestionButtonActive} onClick={() => {
                                    setAttachFile(false)
                                }}> <AddVideoIcon/><ReactTooltip id="AddVideoIcon" type="error" place="bottom">
                                    <span>Stop adding video</span>
                                </ReactTooltip> </a>
                                :
                                <a data-tip data-delay-show='1000' data-for="AddVideoIcon" href="#"
                                   className={iconstyle.suggestionButton} onClick={() => {
                                    setAttachFile(true)
                                }}> <AddVideoIcon/> <ReactTooltip id="AddVideoIcon" type="error" place="bottom">
                                    <span>Add video</span>
                                </ReactTooltip></a>
                        }
                    </span>
                    }


                    {isImageFile && <span>
                        {
                            attachFile ? <a data-tip data-delay-show='1000' data-for="AddImageIcon" href="#"
                                            className={iconstyle.suggestionButtonActive} onClick={() => {
                                    setAttachFile(false)
                                }}> <AddImageIcon/><ReactTooltip id="AddImageIcon" type="error" place="bottom">
                                    <span>Stop adding images</span>
                                </ReactTooltip> </a>
                                :
                                <a data-tip data-delay-show='1000' data-for="AddImageIcon" href="#"
                                   className={iconstyle.suggestionButton} onClick={() => {
                                    setAttachFile(true)
                                }}> <AddImageIcon/> <ReactTooltip id="AddImageIcon" type="error" place="bottom">
                                    <span>Add images</span>
                                </ReactTooltip></a>
                        }
                    </span>
                    }
                    {hasImage && <span>
                        {
                            doDeleteImage ? <a data-tip data-delay-show='1000' data-for="RemoveImage" href="#"
                                            className={iconstyle.suggestionButtonActive} onClick={() => {
                                    deleteImage(false)
                                }}> <DeleteImage2Icon/><ReactTooltip id="RemoveImage" type="error" place="bottom">
                                    <span>Stop</span>
                                </ReactTooltip> </a>
                                :
                                <a data-tip data-delay-show='1000' data-for="RemoveImage" href="#"
                                   className={iconstyle.suggestionButton} onClick={() => {
                                    deleteImage(true)
                                }}> <DeleteImageIcon/> <ReactTooltip id="RemoveImage" type="error" place="bottom">
                                    <span>Delete image from node</span>
                                </ReactTooltip></a>
                        }
                    </span>
                    }
                    {isPdfFile && <span>
                        {
                            attachFile ? <a data-tip data-delay-show='1000' data-for="PdfFileIcon" href="#"
                                            className={iconstyle.suggestionButtonActive} onClick={() => {
                                    setAttachFile(false)
                                }}> <PdfFileIcon/><ReactTooltip id="PdfFileIcon" type="error" place="bottom">
                                    <span>Stop adding PDF</span>
                                </ReactTooltip> </a>
                                :
                                <a data-tip data-delay-show='1000' data-for="PdfFileIcon" href="#"
                                   className={iconstyle.suggestionButton} onClick={() => {
                                    setAttachFile(true)
                                }}> <PdfFileIcon/> <ReactTooltip id="PdfFileIcon" type="error" place="bottom">
                                    <span>Add PDF</span>
                                </ReactTooltip></a>
                        }
                    </span>
                    }
                    {isAudioFile && <span>
                        {
                            attachFile ? <a data-tip data-delay-show='1000' data-for="AddMusicIcon" href="#"
                                            className={iconstyle.suggestionButtonActive} onClick={() => {
                                    setAttachFile(false)
                                }}> <AddMusicIcon/><ReactTooltip id="AddMusicIcon" type="error" place="bottom">
                                    <span>Stop adding Audio</span>
                                </ReactTooltip> </a>
                                :
                                <a data-tip data-delay-show='1000' data-for="AddMusicIcon" href="#"
                                   className={iconstyle.suggestionButton} onClick={() => {
                                    setAttachFile(true)
                                }}> <AddMusicIcon/> <ReactTooltip id="AddMusicIcon" type="error" place="bottom">
                                    <span>Add Audio</span>
                                </ReactTooltip></a>
                        }
                    </span>
                    }
                    <span>
                        {showSubNodes ?
                            <a data-tip data-delay-show='1000' data-for="ShowSubNodesSelectedIcon" href="#"
                               className={iconstyle.suggestionButton} onClick={() => {
                                handleShowSubNodes()
                            }}> <ShowSubNodesSelectedIcon/>
                                <ReactTooltip id="ShowSubNodesSelectedIcon" type="error" place="bottom"><span>Stop showing referring nodes</span></ReactTooltip>
                            </a>

                            :
                            <a data-tip data-delay-show='1000' data-for="ShowSubNodesIcon" href="#"
                               className={iconstyle.suggestionButton} onClick={() => {
                                handleShowSubNodes()
                            }}> <ShowSubNodesIcon/> <ReactTooltip id="ShowSubNodesIcon" type="error"
                                                                  place="bottom"><span>Show referring nodes</span></ReactTooltip></a>

                        }
                    </span>
                </ul>
            </nav>

            <div className="firstdiv">
                {(addTriple) &&
                    <PropertiesSelector triples={triples} searchString={""} setAddTriple={setAddTriple}
                                        toggleRefreshData={toggleRefreshData}
                                        setToggleRefreshData={setToggleRefreshData}/>
                }
                {addType &&
                    <TypesSelector recordID={recordID} setAddType={setAddType} toggleRefreshData={toggleRefreshData}
                                   setToggleRefreshData={setToggleRefreshData}/>}
                {attachFile &&
                    <UploadFiles extentie={nodeType} recordID={recordID} setAttachFile={setAttachFile}
                                 toggleRefreshData={toggleRefreshData} setToggleRefreshData={setToggleRefreshData}/>
                }
                {doDeleteImage &&
                    <span>
                        <h4>Deleting this triple will remove the image permanently</h4>
                        <TripleDelete mytriple={contentUrlImageTriple} toStartEdit={toStartEdit} setIsChanged={setIsChanged} setToggleRefreshData={setToggleRefreshData} instructionType={"delete"}/>
                    </span>}
                <span>
                    {triples.map((mytriple, i) => (
                        <div className={editstyle.node} key={i}>
                            <ReturnValueEdit id={i} mytriple={mytriple}
                                             toggleRefreshData={toggleRefreshData}
                                             setToggleRefreshData={setToggleRefreshData} setFileUrl={setFileUrl}/>
                        </div>
                    ))}
                </span>
                <span>
                    {nodeType === "ImageObject" &&
                        <span>
                        <ShowImage identifier={recordID} toggleRefreshData={toggleRefreshData} lowRes={false}
                                   fileType="image/jpeg" fileUrl={fileUrl}/></span>}
                </span>
                {nodeType === "MediaObject" &&
                    <span>
                        <ShowImage identifier={recordID} toggleRefreshData={toggleRefreshData} lowRes={false}
                                   fileType="MediaType.APPLICATION_PDF" fileUrl={fileUrl}/>
                    </span>
                }
                {nodeType === "AudioObject" &&
                    <span>
                        <ShowImage identifier={recordID} toggleRefreshData={toggleRefreshData} lowRes={false}
                                   fileType="audio/mp3" fileUrl={fileUrl}/>
                    </span>
                }
                {nodeType === "VideoObject" &&
                    <span>
                        <ShowImage identifier={recordID} toggleRefreshData={toggleRefreshData} lowRes={false}
                                   fileType="video/mp4" fileUrl={fileUrl}/>
                    </span>
                }
                <span>
            </span>
        </div>
    </span>

    );
};

export default ReturnRecordEdit;

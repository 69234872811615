// innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged}
import React from "react";
import editStyle from "../../../css/edittriple.module.css";

function EditFloat(props) {


    const handleChangeValue = (value) => {
        props.setUpdateNode({...props.updateNode, value: value})
        props.setIsChanged(true)
    }

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }

    return (
        <label className={editStyle.editInline}>
            <input name={props.id} type={"radio"} value={"Float"}
                   checked={props.updateNode.valueType === "Float"}
                   onChange={() => handleChangeType("Float")}/>
            &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            {props.updateNode.valueType === "Float" &&
            <input type={"number"} step={"0.000000000000001"}
                   value={props.updateNode.value}
                   onChange={e => handleChangeValue(parseFloat(e.target.value))}/>
            }
        </label>
    )
}
export default EditFloat

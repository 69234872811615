import React, {useEffect, useRef, useState} from "react";

import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import UserService from "../../services/user.service";
import ExtractType from "./TripleFunctions/ExtractType";
import EditText from "./EditFunctions/EditText";
import EditDate from "./EditFunctions/EditDate";
import EditDateTime from "./EditFunctions/EditDateTime";
import EditTime from "./EditFunctions/EditTime";
import EditFloat from "./EditFunctions/EditFloat";
import EditInteger from "./EditFunctions/EditInteger";
import EditNumber from "./EditFunctions/EditNumber";
import EditBoolean from "./EditFunctions/EditBoolean";
import EditUrl from "./EditFunctions/EditUrl";
import EditInternalLink from "./EditFunctions/EditInternalLink";
import Update from "./TripleFunctions/Update";
import {ReactComponent as SaveIcon} from "../Icons/Save.svg";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as CancelIcon} from "../Icons/Cancel.svg";
import editstyle from "../../css/edittriple.module.css";
import ReactTooltip from 'react-tooltip';
import objectHash from "object-hash";
import * as DOMPurify from "dompurify";
import * as Constants from"../../components/Authentication/Settings/Settings"


/**
 *
 * @param mytriple
 * @param toStartEdit
 * @param setIsChanged
 * @param setToggleRefreshData
 * @param instructionType update, insert, delete, addType
 * @returns {JSX.Element}
 * @constructor
 */
const TripleEditor = ({id, mytriple, toStartEdit, setIsChanged, setToggleRefreshData, instructionType}) => {
    const [isNowLoading, setIsNowLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [alsoDeleteTriples, setAlsoDeleteTriples] = useState(false);
    const [triplesToDeleteAlso, setTriplesToDeleteAlso] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [isBC, setIsBC] = useState(false);
    const [doToggleSave, setDoToggleSave] = useState(false);
    // const [languageCode, setLanguageCode] = useState("");
    const [updateNode, setUpdateNode] = useState({
        identifier: "",
        fieldName: "",
        value: "",
        valueType: "",
        languageOfValue: "",
        graph: "",
        doNotEdit: false,
        internalLink: false,
        imageObject: false
    });
    const isInitialMount = useRef(true);
    // https://github.com/wojtekmaj/react-calendar
    const [dateState, setDateState] = useState(new Date())
    const [dateTimeState, setDateTimeState] = useState(new Date())
    const [timeState, setTimeState] = useState(moment(Date.now()).format('HH:mm'))
    const [showSaveButton, setShowSaveButton] = useState(true);

    const cancelAction = () => {
        console.log("hier: ")
        console.log(updateNode)
        setUpdateNode({...updateNode, value: "", valueType: ""})
        setIsChanged(false)
        toStartEdit(false)
    }

    const handlesetHideSaveButton = (value) => {
        setShowSaveButton(value)
    }

    const hashTriple = (triple) => {
        return objectHash(triple)
    }

    useEffect(() => {
        if (isSuccess) {
            setToggleRefreshData(true)
            cancelAction()
        } else {
            setIsChanged(false)
        }
    }, [isSuccess]);


    useEffect(() => {
         if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
             if (updateNode.valueType === "URL" && instructionType === "update") {
                 if (!/(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+(\/[^\s]*)?$/g.test(updateNode.value)) {
                     window.alert("Url is wrong");
                     return;
                 }
             }
             console.log("------------------------------->>>>UPDATE NODE START")
             console.log("updateNode.valueType = " + updateNode.valueType)
             console.log("instructionType = " + instructionType)

             if (updateNode.valueType === "Text"){
                 console.log("updateNode.value = " + updateNode.value)
                 // let updateString = DOMPurify.sanitize(updateNode.value, {ALLOWED_TAGS: ['p', 'a', 'img', 'strong', 'i', 'sup']}, {USE_PROFILES: {html: true}})
                 let updateString = DOMPurify.sanitize(updateNode.value, {ALLOWED_TAGS: Constants.allowedHtml}, {USE_PROFILES: {html: true}})
                 // updateString = updateString.replaceAll("\\","&#92;");
                 console.log("updateString  = " + updateString);
                 setUpdateNode({...updateNode, value: updateString});
                 console.log("updateNode.valueType = " + updateNode.valueType)
                 console.log("instructionType = " + instructionType)
             }
             console.log("<<<<-------------------------------UPDATE NODE END")

             let doUpdate = true;
            switch (updateNode.value) {
                case (""):
                    window.confirm('Input is empty');
                    doUpdate = false;
                    break;
                case (mytriple.value):
                    if (updateNode.languageOfValue === mytriple.languageOfValue) {
                        window.confirm("Data hasn\'t been changed");
                        let doUpdate = false;
                        break;
                    }
                default :

            }
            if (doUpdate) {
                Update({mytriple, updateNode, instructionType, setIsLoading: setIsNowLoading, setIsSuccess})
            }
        }
    }, [doToggleSave]);

    // Hierkomen we binnen
    useEffect(() => {
        console.log("++++++instructionType = " + instructionType + " ++++++++")
        console.log("Start TripleEditor useEffect")
        console.log(mytriple)
        console.log(updateNode)
        console.log(instructionType)
        console.log("End TripleEditor useEffect")
        setIsNowLoading(true);
        // UserService.getMetaData("selectExpectedTypesList", mytriple.fieldName, mytriple.value, mytriple.valueType).then(
        UserService.getMetaData("selectExpectedTypesList", mytriple.fieldName, "", mytriple.valueType).then(
            (response) => {
                console.log("UserService.getMetaData(\"selectExpectedTypesList")
                console.log(response.data)
                setNodes(response.data.returnRecord);
                console.log("searchString = " + response.data.searchString)
                console.log("status = " + response.data.status)
                console.log("timeStamp = " + response.data.timeStamp)
                console.log("mytriple.value = " + mytriple.value)
                console.log(response.data.returnRecord[0].languageValues)
                console.log(response.data.returnRecord)
                if (mytriple.valueType.startsWith("d")) {
                    if (mytriple.value.startsWith("-")) {
                        setIsBC(true)
                    }
                    setDateState(moment(mytriple.value, 'YYYY-MM-DD').toDate())
                    setDateTimeState(moment(mytriple.value, 'YYYY-MM-DD HH:mm:ss').toDate())
                }
                if (mytriple.valueType === "t") {
                    setTimeState(moment(mytriple.value, 'HH:mm:ss').toDate())
                }

                setUpdateNode({
                    ...updateNode,
                    valueType: ExtractType(mytriple),
                    value: mytriple.value,
                    languageOfValue: mytriple.languageOfValue
                })
                // setCurrentNode(mytriple);
                for (let i = 0; i < response.data.returnRecord.length; i++) {
                    for (let j = 0; j < response.data.returnRecord[i].returnValues.length; j++) {
                        console.log("in for loop")
                        console.log(response.data.returnRecord[i].returnValues[j])
                        if (response.data.returnRecord[i].returnValues[j].doNotEdit) {
                            setUpdateNode({
                                ...updateNode,
                                valueType: response.data.returnRecord[i].returnValues[j].value
                            })
                            console.log(response.data.returnRecord[i].returnValues[j].value)
                        }
                    }
                }
                console.log("end for loop")
                setIsNowLoading(false);
            },
            (error) => {
                setIsNowLoading(false);
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setContent2(_content);
            }
        );
    }, []);


    return (
        <span>{isNowLoading && <>Updating Please Wait...</>}
            <p>Property is {mytriple.fieldName}</p>
            <div>
                {nodes.map((node,i) => (
                    <div key={i}>
                        {node.returnValues.map((innerNode,i) => (
                            <span key={i}>
                                {innerNode.value.endsWith("Text") && <EditText id={id} innerNode={innerNode} node={node} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("Date") && <EditDate id={id} isBC={isBC} setIsBC={setIsBC} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("DateTime") && <EditDateTime id={id} isBC={isBC} setIsBC={setIsBC} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("Time") && <EditTime id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("Float") && <EditFloat id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("Integer") && <EditInteger id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("Number") && <EditNumber id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("Boolean") && <EditBoolean id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.value.endsWith("URL") && <EditUrl id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setShowSaveButton={setShowSaveButton}/>}
                                {innerNode.internalLink && <EditInternalLink id={id} innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setDoToggleSave={setDoToggleSave} setShowSaveButton={setShowSaveButton}/>}
                            </span>
                        ))}
                    </div>
                ))}
                </div>
            <div className={editstyle.showInlineProperty}>
                {showSaveButton &&
                    <span>
                        <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" className={iconstyle.suggestionButton} onClick={() => setDoToggleSave(!doToggleSave)}> <SaveIcon/> </a>
                            <ReactTooltip id="SaveIcon" type="error">
                            <span>Save changes</span>
                            </ReactTooltip>
                    </span>
                }
                <a data-tip data-for="CancelIcon" data-delay-show='1000' href="#" className={iconstyle.suggestionButton} onClick={() => cancelAction()}> <CancelIcon/> </a>
                <ReactTooltip id="CancelIcon" type="error">
                    <span>Cancel edit triple</span>
                </ReactTooltip>
            </div>
        </span>
    );
}

export default TripleEditor;

import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import {ReactComponent as DataConnectionIcon} from '../Icons/DataConnection.svg'
import editstyle from "../../css/edittriple.module.css";
import iconstyle from "../../css/icons.module.css";
import ReactTooltip from "react-tooltip";
import {ReactComponent as SiteMapIcon} from "../Icons/SiteMap.svg";
import {Helmet} from "react-helmet";
import {
    BlockText,
    EventFlexGrid,
    EventResult,
    PBlog
} from "./ResyncIndices.elements";

const ResyncIndices = () => {
    const currentUser = AuthService.getCurrentUser();
    const [graphBatchRw, setGraphBatchRw] = useState("");

    const [mynumber, setMynumber] = useState(-1);
    const [returnText, setReturnText] = useState("");
    const [message, setMessage] = useState(false);

    /**
     * 1    =   Get records from source
     * 2    =   Process triples raw
     * 4    =   update Webpage
     * 8    =   Update Json-LD
     * 16   =   Empty
     * 32   =   Empty
     * 64   =   Empty
     * 128  =   Empty
     * 256  =   Empty
     *
     * @param instruction
     */

    const syncServices = (instruction) => {
        // e.preventDefault();
        let messageText = "";
        switch (instruction) {
            case(4):
                messageText = "Resync Webpages Index";
                break;
            case(8):
                messageText = "Resync Structured Data Indices";
                break;
            case(12):
                messageText = "Resync All Indices";
                break;
            case(17):
                messageText = "Resyncing deeplearning image recognition from the knowledge graph";
                break;
            case(15):
                messageText = "Resync All Indices";
                break;
        }
        UserService.syncServices(instruction).then(
            (response) => {
                console.log(response.data)
                setMynumber(response.data.numberOfRecords)
                // setKey(response.data.requested);
                setReturnText(messageText + " " + response.data.accompanyingText)
                console.log("status = " + response.data.status)
                console.log("timeStamp = " + response.data.timeStamp)
                setMessage(true)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setContent2(_content);
            }
        );
    }

    const GenerateSitemap = () => {
        // e.preventDefault();
        UserService.generateSitemap().then(
            (response) => {
                console.log(response.data)
                setMynumber(response.data.numberOfRecords)
                // setKey(response.data.requested);
                setReturnText("Generate sitemap " + response.data.accompanyingText)
                console.log("status = " + response.data.status)
                console.log("timeStamp = " + response.data.timeStamp)
                setMessage(true)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setContent2(_content);
            }
        );
    }

    return (
        <div className="headDiv">
            <Helmet>
                <title>Resync Indices</title>
            </Helmet>
            <EventResult>
                {message && (
                    <div className="form-group">
                        <div className="alert alert-success" role="alert">
                            {returnText}
                        </div>
                    </div>
                )}

                <EventFlexGrid>
                    <BlockText>
                        {/*<EventTextWrapper>*/}
                        <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" justify-content="center"
                           className={iconstyle.mediumButton}
                           onClick={() => syncServices(12)}> <DataConnectionIcon/> </a>
                        <ReactTooltip id="SaveIcon" type="error">
                            <span>Start resync indices</span>
                        </ReactTooltip>
                        <PBlog>Resync All Indices</PBlog>
                        {/*</EventTextWrapper>*/}
                    </BlockText>
                    <BlockText>
                        <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" justify-content="center"
                           className={iconstyle.mediumButton}
                           onClick={() => syncServices(4)}> <DataConnectionIcon/> </a>
                        <ReactTooltip id="SaveIcon" type="error">
                            <span>Start resync indices</span>
                        </ReactTooltip>
                        <PBlog>Resync Webpages Index</PBlog>
                    </BlockText>
                    <BlockText>
                        <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" justify-content="center"
                           className={iconstyle.mediumButton}
                           onClick={() => syncServices(8)}> <DataConnectionIcon/> </a>
                        <ReactTooltip id="SaveIcon" type="error">
                            <span>Start resync indices</span>
                        </ReactTooltip>
                        <PBlog>Resync Structured Data Indices</PBlog>
                    </BlockText>
                    {/*<BlockText>*/}
                    {/*    <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" justify-content="center"*/}
                    {/*       className={iconstyle.mediumButton}*/}
                    {/*       onClick={() => syncServices(9)}> <DataConnectionIcon/> </a>*/}
                    {/*    <ReactTooltip id="SaveIcon" type="error">*/}
                    {/*        <span>Start resync indices</span>*/}
                    {/*    </ReactTooltip>*/}
                    {/*    <PBlog>Resync ElasticSearch Indices</PBlog>*/}
                    {/*</BlockText>*/}
                    {/*<BlockText>*/}
                    {/*    <a data-tip data-for="SaveIcon" data-delay-show='1000' href="#" justify-content="center"*/}
                    {/*       className={iconstyle.mediumButton}*/}
                    {/*       onClick={() => syncServices(17)}> <DataConnectionIcon/> </a>*/}
                    {/*    <ReactTooltip id="SaveIcon" type="error">*/}
                    {/*        <span>Start resync indices</span>*/}
                    {/*    </ReactTooltip>*/}
                    {/*    <PBlog>Resyncing deeplearning image recognition from the knowledge graph</PBlog>*/}
                    {/*</BlockText>*/}
                    <BlockText>
                        <a data-tip data-for="SiteMapIcon" data-delay-show='1000' href="#" justify-content="center"
                           className={iconstyle.mediumButton}
                           onClick={() => GenerateSitemap()}> <SiteMapIcon/> </a>
                        <ReactTooltip id="SiteMapIcon" type="error">
                            <span>Start resync indices</span>
                        </ReactTooltip>
                        <PBlog>Generate sitemap</PBlog>
                    </BlockText>
                </EventFlexGrid>
            </EventResult>

        </div>
    );


}
export default ResyncIndices;

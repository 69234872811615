/**
 *
 * @param mytriple
 * @returns {string}
 * @constructor
 */
function ExtractType(mytriple) {


    console.log("extractType")
    console.log(mytriple)
    switch (mytriple.valueType) {
        case ("d"):
            console.log("Date")
            return ("Date")
        case ("t"):
            console.log("Time")
            return ("Time")
        case ("dt"):
            console.log("DateTime")
            return ("DateTime")
        case ("n"):
            console.log("Number")
            return ("Float")
        case ("i"):
            console.log("Integer")
            return ("Integer")
        case ("f"):
            console.log("Float")
            return ("Float")
        case ("b"):
            console.log("Boolean")
            return ("Boolean")
        case ("s"):
            console.log("Text")
            return ("Text")
        case ("iri"):
            console.log("URL")
            if (!mytriple.internalLink) {
                return ("URL")
                break
            }

        default:
            return ("Text")
    }
    // return "Text"
}

export default ExtractType

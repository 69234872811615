import styled from 'styled-components';
import * as Constants from "../../components/Authentication/Settings/Settings";
import {Container} from "../../GlobalStyles";

export const EventResult = styled.div`
   min-width: 275px;
   max-width:90vw;
 //  border: 2px solid green;
  `;

export const EventGrid = styled(Container)`
 
`;

export const EventFlexGrid = styled(Container)`
   display: flex;
   flex-direction: row;
   align-items: top;
   flex-wrap: wrap;
   gap: 30px;
  // border: 2px solid blue;
`;


export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  
  align-items: center;
  min-width: 200px;
  max-width: 275px;
  // border: 2px solid yellow;

`;

export const EventImgWrapper = styled.div`
 
`;

export const EventTextWrapper = styled.div`
 width: 200px;
 // border: 2px solid pink;
`;

export const PBlog = styled.h4`
  text-align:center;

`;
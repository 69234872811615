import React, {useState, useEffect, useRef} from "react";
import GeneralSearch from "./GeneralSearch";
import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from "../Icons/favicon/favicon.ico";

// import {base64StringToBlob} from 'blob-util';

const Search = () => {
    // Variables
    const [link, setLink] = useState("");
    const [headerText, setHeaderText] = useState("Search");
    const [searchOffset, setSearchOffset] = useState(100);
    const updateAdvancedSearch = e => {

    }

    return (
        <span>
            <Helmet>
                <title>Datamachine Workbench Search</title>
            </Helmet>
            <div className="headDiv">
                <h3>{headerText}</h3>
            </div>
                <GeneralSearch setHeaderText={setHeaderText} fieldName={"objectName"} link={false} setLink={setLink} label={""} placeHolderText={"Enter search terms"} size={50} autoSuggest={true} searchOffset={searchOffset}/>
        </span>
);
};

export default Search;

/** GeneralSearch
*
* @param setHeaderText Text om te gebruiken in een headerbanner
* @param elasticFieldName Het veld wat gebruikt kan worden om te zoeken op een specifieke property
* @param type Wat is de Type van de node. Default is het Thing (=undefined)
* @param link True of False, True: alleen een link teruggeven. False: De node editten
* @param setLink
* @param label Niet gebruikt
* @param placeHolderText De text in het  zoekveld
* @param size Niet gebruikt
* @param autoSuggest true of false voor suggestions uit Elastic
* @param searchOffset Het aantal records per pagina
* @returns
    {
        JSX.Element
    }
* @constructor
*/

import React, {Component, useEffect, useState} from "react";
import UserService from "../../services/user.service";

class UploadFiles extends Component {

    // state = {selectedFiles: null};
    constructor(props) {
        super(props);
        this.state = {
            fileTypes: "x",
            allTypes: true,
            selectedFiles: null,
            myFormData: new FormData(),
            isLoading: false

        };
    }

    componentDidMount() {
        switch (this.props.extentie) {
            case ("3DModel"):
                break;
            case ("AudioObject"):
            case ("Audiobook"):
                this.handleSetFiletypes(".mp3")
                break;
            case ("ImageObject"):
            case ("Barcode"):
                this.handleSetFiletypes(".jpg, .png, .jpeg, .gif, .bmp, .tif, .svg, .tiff|image/*")
                break;
            case ("LegislationObject"):
                break;
            case ("MusicVideoObject"):
            case ("VideoObject"):
                break;
            case ("DataDownload"):
            case ("MediaObject"):
            case("DigitalDocument"):
                this.handleSetFiletypes(".pdf")
                break;
            default:
                this.handleSetFiletypes(".*")
        }

    }

    // On file select (from the pop up)
    onFileChangeHandler = (e) => {
        this.setState(prevState => { return {isLoading : true} })
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append('file', e.target.files[i])
        }
        formData.append("recordID", this.props.recordID);
        formData.append("fileType", this.props.extentie)
        UserService.uploadFile(formData)
            .then(response => {
                    console.log(response.data);
                    this.props.setAttachFile(false);
                    if (response.data.status === 200){
                        alert("File uploaded successfully.")
                        this.setState(prevState => { return {isLoading : false} })
                        this.props.setToggleRefreshData(!this.props.toggleRefreshData)
                    }
                    else {
                        alert("File uploaded not successfully.")
                        this.setState(prevState => { return {isLoading : false} })
                    }
                },
                (error) => {
                    this.props.setAttachFile(false);
                    alert("File uploaded not successfully.")
                    this.setState(prevState => { return {isLoading : false} })
                }
            )
    };

    handleSetFiletypes = (value) => {
        this.setState(prevState => {
            return {fileTypes: value}
        })
    }


    render() {
        const {fileTypes, allTypes, isLoading} = this.state

        return (
            <span>
                <h4>Upload files here {isLoading && <>Loading...</>}</h4>
                    <input type="file" accept={fileTypes} className="form-control" name="file" multiple onChange={this.onFileChangeHandler}/><br/>
            </span>
        );
    }
}

export default UploadFiles;

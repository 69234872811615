// innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged} setDoToggleSave={setDoToggleSave}
import GeneralSearch from "../../Search/GeneralSearch";
import React, {useEffect, useState} from "react";
import editStyle from "../../../css/edittriple.module.css";


/**
 *
 * @param props:
 * @param id
 * @param innerNode
 * @param updateNode
 * @param setUpdateNode
 * @param setIsChanged
 * @param setDoToggleSave
 * @returns {JSX.Element}
 * @constructor
 */
function EditInternalLink(props) {
    const [searchText, setSearchText] = useState("");

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(false)
    }

    const setSearchNodeLink = (inputValue) => {
        props.setUpdateNode({...props.updateNode, value: inputValue, valueType: "schema:URL"})
        props.setIsChanged(true)
        props.setDoToggleSave(true)
    }

    const setHeaderText = (value) => {
        //does nothing
    }

    return (
        <label className={editStyle.editInline}>
            <input name={props.id} type={"radio"} value={props.innerNode.value}
                   checked={props.updateNode.valueType === props.innerNode.value}
                   onChange={() => handleChangeType(props.innerNode.value)}/>
            &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            {props.updateNode.valueType === props.innerNode.value &&
                <span>
                    <p className="searchParagraph" >{searchText}</p>
                    <GeneralSearch setHeaderText={setSearchText} elasticFieldName={"objectName"} type={props.innerNode.value} link={true} setLink={setSearchNodeLink} placeHolderText={props.updateNode.identifier} autoSuggest={true} searchOffset={10000} />
                </span>
            }
        </label>
    )
};

export default EditInternalLink

/**GeneralSearch
 *
 * @param setHeaderText Text om te gebruiken in een headerbanner
 * @param elasticFieldName Het veld wat gebruikt kan worden om te zoeken op een specifieke property
 * @param type Wat is de Type van de node. Default is het Thing
 * @param link True of False, True: alleen een link teruggeven. False: De node editten
 * @param setLink
 * @param label Niet gebruikt
 * @param placeHolderText De text in het  zoekveld
 * @param size Niet gebruikt
 * @param autoSuggest true of false voor suggestions uit Elastic
 * @param searchOffset Het aantal records per pagina
 * @returns {JSX.Element}
 * @constructor
 */

import React, {useEffect, useRef, useState} from "react";
import UserService from "../../services/user.service";
import editstyle from "../../css/edittriple.module.css";
import nodeStyle from "../../css/node.module.css"
import TripleEditor from "./TripleEditor";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as AddTripleIcon} from "../Icons/AddTriple.svg";
import {ReactComponent as CancelIcon} from "../Icons/Cancel.svg";
import {ReactComponent as CheckIcon} from "../Icons/Check.svg"
import ReactTooltip from 'react-tooltip';

/**
 *
 * @param instruction
 * @param triples
 * @param searchString
 * @param setAddTriple
 * @param toggleRefreshData
 * @param setToggleRefreshData
 * @returns {JSX.Element}
 * @constructor
 */
const PropertiesSelector = ({
                                instruction,
                                triples,
                                searchString,
                                setAddTriple,
                                toggleRefreshData,
                                setToggleRefreshData
                            }) => {
    const typeList = [];
    // const [toggleMetadata, setToggleMetadata] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const isInitialMount = useRef(true);
    const [triplesMetaData, setTriplesMetaData] = useState([]);
    // const [appendTripple, setAppendTripple] = useState(false);
    const [startEdit, toStartEdit] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [addedNode, setAddedNode] = useState({
        identifier: "",
        fieldName: "",
        value: "",
        valueType: "",
        languageOfValue: "",
        graph: "",
        doNotEdit: false,
        internalLink: false,
        imageObject: false
    });

    const setProperty = (value) => {
        setAddedNode({...addedNode, fieldName: value})
        toStartEdit(true)
    }

    // const handleStartEdit = (value) => {
    //     toStartEdit(value);
    // }
    const cancelAction = () => {
        setAddTriple(false)
    }

    // useEffect(() => {
    //     if (isInitialMount.current) {
    //         isInitialMount.current = false;
    //     } else {
    //         console.log("PropertiesSelector useEffect appendTripple gaat af")
    //         setAddTriple(false)
    //     }
    // }, [toggleRefreshData]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (toggleRefreshData === true) {
                setAddTriple(false)
            }
        }
    }, [toggleRefreshData])


    // hier komen we binnen
    useEffect(() => {
        setIsLoading(true);
        console.log("PropertiesSelector->useEffect: " + instruction)
        triples.map((triple) => {
            if (triple.fieldName.endsWith(":type")) {
                typeList.push(triple.value)
            }
        });
        setAddedNode({...addedNode, identifier: triples[0].identifier})
        UserService.getMetaData("selectPropertiesList", typeList, null, null).then(
            (response) => {
                console.log("selectPropertiesList")
                console.log(response.data)
                setTriplesMetaData(response.data.returnRecord[0].returnValues)
                setIsLoading(false);
            }
            ,
            (error) => {
                setIsLoading(false);
            }
        );
    }, []);


    return (
        <div>
            <div className={editstyle.showInlineType}>
                Select property&nbsp;&nbsp;{isLoading && <>Loading...</>}
            </div>
            <div className={nodeStyle.datatable}>
                {!startEdit && <table>
                    <tbody>
                    <tr>
                        <th>Add property</th>
                        <th>Property</th>
                        <th>Description</th>
                    </tr>
                    {triplesMetaData.map((schemaPropertyTriple, i) =>
                        <tr key={i}>
                            <td>
                                <a data-tip data-for="AddTripleIcon" data-delay-show='1000' href="#"
                                   className={iconstyle.suggestionButton} onClick={() => {
                                    setProperty(schemaPropertyTriple.identifier)
                                }}> <CheckIcon/>
                                    <ReactTooltip id="AddTripleIcon" type="error">
                                        <span>Add predicate to the node</span>
                                    </ReactTooltip>
                                </a>

                            </td>

                            <td>
                                {schemaPropertyTriple.identifier}
                            </td>
                            <td>
                                <div dangerouslySetInnerHTML={{__html: schemaPropertyTriple.value}}/>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>}
                {startEdit && <TripleEditor mytriple={addedNode}
                                            toStartEdit={toStartEdit}
                                            setIsChanged={setIsChanged}
                                            setToggleRefreshData={setToggleRefreshData}
                                            instructionType={"insert"}/>}
            </div>
            <div className={editstyle.showInlineType}>
                <a data-tip data-for="CancelIcon" data-delay-show='1000' href="#" className={iconstyle.suggestionButton}
                   onClick={() => cancelAction()}> <CancelIcon/> </a> cancel select property
                <ReactTooltip id="CancelIcon" type="error">
                    <span>Cancel adding triple</span>
                </ReactTooltip>
            </div>
            <br/>
            <br/>
        </div>
    );
}
export default PropertiesSelector;

import axios from "axios";
import authHeader from "./auth-header";
import * as urlHeader from "./url-header";

//-------------------------------------POST CALLS-------------------------------------//

const test = (identifier, index) => {
    let dataStructure = {
        identifier,
        index,
    }
    return axios.post(urlHeader.urlHeader + "getNode", {dataStructure})
};

const userManagement = (instruction, userValues) => {
    let dataStructure = {
        instruction,
        userValues,
    }
    console.log("dataStructure = " + dataStructure)
    return axios.post(urlHeader.urlHeader + "userManagement", {dataStructure}, {headers: authHeader()})
};

const registerUser = (username, email, password) => {
    return axios.post(urlHeader.urlHeader + "registerUser", {
        username,
        email,
        password,
    }, {headers: authHeader()});
};

const getUpdate = (instruction) => {
    return axios.post(urlHeader.urlHeader + "getUpdate", {
            instruction,
        },
        {headers: authHeader()});
}

const syncServices = (instruction) => {
    return axios.post(urlHeader.urlHeader + "syncServices", {
            instruction,
        },
        {headers: authHeader()});
}

const generateSitemap = () => {
    return axios.post(urlHeader.urlHeader + "generateSitemap", {},
        {headers: authHeader()});
}

const imageFunctions = (requestedFunction) => {
    let dataStructure = {
        requestedFunction,
    }
    return axios.post(urlHeader.urlHeader + "imageFunctions", {}, {headers: authHeader(), params: dataStructure});
}

/**
 *
 * @param currentTriple
 * @param updateTriple
 * @param instructionType mogelijke waardes voor instruction: update, insert, delete, addType
 * @returns {Promise<AxiosResponse<any>>}
 */
const saveTriple = (currentTriple, updateTriple, instructionType) => {
    console.log(instructionType + ": " + currentTriple.value + ", " + updateTriple.value)
    let dataStructure = {
        instructionType,
        currentTriple,
        updateTriple
    }
    console.log(dataStructure)

    return axios.post(urlHeader.urlHeader + "updateTriples", {dataStructure}, {headers: authHeader()})
}

const manageSchemaRole = (sIdentifier, pProperty, oObject, instruction) => {
    console.log(sIdentifier + ": " + pProperty + ", " + oObject + ", " + instruction)
    let dataStructure = {
        sIdentifier,
        pProperty,
        oObject,
        instruction
    }
    console.log(dataStructure)
    return axios.post(urlHeader.urlHeader + "manageSchemaRole", {dataStructure}, {
        headers: authHeader(),
        params: dataStructure
    })
}

const uploadFile = (formData) => {

    return axios.post(urlHeader.urlHeader + "upload", formData, {headers: authHeader()})
}

const getImage = (imageIdentifier, lowRes, nodeId, fileType) => {
    // console.log("getImage->identifier  = " + imageIdentifier)
    let dataStructure = {
        imageIdentifier,
        lowRes,
        nodeId,
        fileType,
    }
    return axios.post(urlHeader.urlHeader + "getImage", {dataStructure}, {
        headers: authHeader(),
        params: dataStructure
    });
}
/**
 * Zoeken in elastic search backend params
 *
 * @param searchString de te zoeken string
 * @param nodeType     de type of the node, default is Thing
 * @param field        In welk elastic field zoeken, default is alle textvelden
 * @param pageString   page voor pagination, default is 1
 * @param offsetString Hoeveel records per page, default is 10
 * @return
 */
const searchRecord = (search, type, fieldName, page, offset) => {
    console.log("++++++++++++++++++++++++++++ Start searchRecord")
    console.log("search : " + search)
    console.log("type : " + type)
    console.log("fieldName : " + fieldName)
    console.log("page : " + page)
    console.log("offset : " + offset)
    console.log("++++++++++++++++++++++++++++ End searchRecord")
    let dataStructure = {
        searchString: search,
        type: type,
        field: fieldName,
        page: page.toString(),
        offset: offset.toString(),
    }

    return axios.post(urlHeader.urlHeader + "search", {dataStructure}, {headers: authHeader()});
    // return axios.get(urlHeader.urlHeader + "search?page=1&searchString=" + search + "&type=" + type + "&field=" + elasticFieldName + "&page=" + page + "&offset=" + offset, {headers: authHeader()});
}

const listDistinctTypes = () => {
    return axios.post(urlHeader.urlHeader + "getDistinctTypes", {}, {headers: authHeader()})
}

//-------------------------------------GET CALLS-------------------------------------//

// const getUserSearch = () => {
//     return axios.get(urlHeader.urlHeader + "search", {headers: authHeader()});
// };

const getStatus = () => {
    return axios.get(urlHeader.urlHeader + "getStatus", {headers: authHeader()});
};

const getUserBoard = () => {
    return axios.get(urlHeader.urlHeader + "user", {headers: authHeader()});
};

const getModeratorBoard = () => {
    return axios.get(urlHeader.urlHeader + "mod", {headers: authHeader()});
};

const getAdminBoard = () => {
    return axios.get(urlHeader.urlHeader + "admin", {headers: authHeader()});
};

/**
 * @param identifier = zoekvraag
 * @param level      = alleen de node zelf zoeken, 2 = de referred subnodes meegeven, 3 = alleen de referring subnodes geven
 * @param prefix     = 0 = geen prefix voor de P en de O, 1 = wel een prefix
 * @param type       = 0 alles, 1 geen externe links,  2 = get all nodes referring to identifier
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const getRecord = (identifier, level, type) => {
    return axios.get(urlHeader.urlHeader + "getData?identifier=" + identifier + "&level=" + level + "&prefix=true&type=" + type, {headers: authHeader()});
}
// const searchField = (search, field) => {
//     return axios.get(urlHeader.urlHeader + "search?page=1&searchString=" + search + "&field=" + field, {headers: authHeader()});
// }

const getPublicData = (identifier) => {
    const prefix = false;
    const returnPrefix = true;
    const path = true;
    const properties = true;
    const pageUrl = true;
    let level = "0";
    let queryType = "0";
    let mainNode = 0;
    let page = 0;
    let offset = 25;

    let dataStructure = {
        identifier: identifier,
        level: level,
        prefix: prefix,
        returnPrefix: returnPrefix,
        queryType: queryType,
        pageUrl: pageUrl,
        path: path,
        properties: properties,
        index: "1",
        mainNode: mainNode.toString(),
        page: page.toString(),
        offset: offset.toString(),
    }


// console.log("headers.baseurl + \"/test/getNode\" + \"identifier\" = " + headers.baseurl + "/test/getNode" + identifier)

    return axios.post(urlHeader.urlHeader + "getNode", dataStructure, )
}

const getSuggestion = (search, fieldName) => {
    return axios.get(urlHeader.urlHeader + "searchCompletion?searchString=" + search + "&field=" + fieldName, {headers: authHeader()});
}

const getMetaData = (query, search, object, objectType) => {
    console.log("query = " + query)
    console.log("searchv = " + search)
    console.log("object = " + object)
    console.log("objectType = " + objectType)
    return axios.get(urlHeader.urlHeader + "getMetaData?queryType=" + query + "&searchString=" + search + "&object=" + object + "&objectType=" + objectType, {headers: authHeader()});
}

const getUniqueProperties = (identifier, type) => {
    return axios.get(urlHeader.urlHeader + "getUniqueProperties?identifier=" + identifier + "&type=" + type, {headers: authHeader()});
}

// const getImage = (identifier,lowRes, fileType) => {
//     // if (identifier.length > 0) {
//     return axios.get(urlHeader.urlHeader + "getImage?imageIdentifier=" + identifier, {headers: authHeader()});
//     // } else {
//     //     return axios.get(urlHeader.urlHeader + "getImage", {headers: authHeader()});
//     // }
// }

const delTriple = (mytriple) => {
    return "I am deleted " + mytriple.value
}

const getNewIdentifier = () => {
    return axios.get(urlHeader.urlHeader + "getNewIdentifier", {headers: authHeader()});
}


const getUsedTypeOfALinkedNode = (identifier) => {
    return axios.get(urlHeader.urlHeader + "getUsedTypeOfALinkedNode?identifier=" + identifier, {headers: authHeader()});
}

const listSuggestions = (searchString, page, offset,isTempImage) => {
    let dataStructure = {
        searchString,
        page,
        offset,
        isTempImage,
    }
    return axios.post(urlHeader.urlHeader + "listSuggestions", {dataStructure}, {headers: authHeader()});
}


export default {
    // getPublicContent,
    // getUserSearch,
    registerUser,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    getRecord,
    searchRecord,
    // searchField,
    getSuggestion,
    getUpdate,
    syncServices,
    generateSitemap,
    // syncDatabase,
    getStatus,
    getMetaData,
    getUniqueProperties,
    getImage,
    delTriple,
    getNewIdentifier,
    saveTriple,
    uploadFile,
    manageSchemaRole,
    getUsedTypeOfALinkedNode,
    imageFunctions,
    userManagement,
    test,
    listSuggestions,
    listDistinctTypes,
    getPublicData,
};

import React, {useEffect, useState, useRef} from "react";
import AuthService from "../services/auth.service";
import {useHistory} from "react-router";
import navbarstyle from "../css/navbar.module.css";
import {ReactComponent as HomeIcon} from './Icons/home.svg';
import {ReactComponent as SearchIcon} from './Icons/Search.svg';
import {ReactComponent as CaretIcon} from './Icons/caret.svg';
import {ReactComponent as AddNodeIcon} from './Icons/AddNode.svg';
import {ReactComponent as CogIcon} from './Icons/cog.svg';
import {ReactComponent as ChevronIcon} from './Icons/chevron.svg';
import {ReactComponent as ArrowIcon} from './Icons/arrow.svg';
import {ReactComponent as DatamachineWorkbenchIcon} from './Icons/DatamachineWorkbench.svg';
import {ReactComponent as UserIcon} from './Icons/User.svg';
import {ReactComponent as UsersIcon} from './Icons/Users.svg';
import {ReactComponent as LoginIcon} from './Icons/Login.svg';
import {ReactComponent as LogoutIcon} from './Icons/Logout.svg';
import {ReactComponent as IndexSyncIcon} from './Icons/IndexSync.svg';
import {ReactComponent as JigSawImageIcon} from './Icons/JigSawImage.svg';
import {ReactComponent as EmptyIcon} from './Icons/EmptyIcon.svg'
import {CSSTransition} from 'react-transition-group';
import ReactTooltip from "react-tooltip";

const NavBarGroup = () => {

        const [showModeratorBoard, setShowModeratorBoard] = useState(false);
        const [showAdminBoard, setShowAdminBoard] = useState(false);
        const [currentUser, setCurrentUser] = useState(undefined);
        const history = useHistory();
        const [menuState, setMenuState] = useState(false);
        // const [changed, setChanged] = useState(false);

        useEffect(() => {
            const user = AuthService.getCurrentUser();

            if (user) {
                setCurrentUser(user);
                setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
                setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
            }
        }, []);

        const logOut = () => {
            AuthService.logout();
            OpenPage("/")
            window.location.reload();
        };

        function NavBar(props) {
            return (
                <div>
                    <nav className={navbarstyle.navbar}>
                        <ul className={navbarstyle.navbarNavLeft}>
                            <DatamachineWorkbenchIcon/>
                            <NavLink icon={<HomeIcon/>} link={"/"} toolTip={"Home"} toolTipPage={"Home"}> </NavLink>
                            {currentUser && <NavLink icon={<SearchIcon/>} link={"/search"} toolTip={"Search the database"} toolTipPage={"searchTip"}> </NavLink>}
                            {currentUser && <NavLink icon={<AddNodeIcon/>} link={"/new"} toolTip={"Insert a new node in the database"} toolTipPage={"newTip"}> </NavLink>}
                        </ul>
                        <ul className={navbarstyle.navbarNav}>
                            {props.children}
                        </ul>
                    </nav>
                </div>
            );
        }

        function NavLink(props) {
            const [open, setOpen] = useState(false);
            useEffect(() => {
                setOpen(false);
            }, [menuState])
            return (
                <li className={navbarstyle.navItem}>
                    <a data-tip data-for={props.toolTipPage} href="#" className={navbarstyle.iconButton} onClick={() => OpenPage(props.link)}>
                        {props.icon}
                        <ReactTooltip id={props.toolTipPage} type="error" delayShow={1000}>
                            <span>{props.toolTip}</span>
                        </ReactTooltip>
                    </a>
                </li>
            );
        }

        function NavItem(props) {
            const [open, setOpen] = useState(false);
            useEffect(() => {
                setOpen(false);
            }, [menuState])
            return (
                <li className={navbarstyle.navItem}>
                    <a href="#" className={navbarstyle.iconButton} onClick={() => setOpen(!open)}>
                        {props.icon}
                    </a>
                    {open && props.children}
                </li>
            );
        }

        function OpenPage(link) {
            console.log("link = " + link)
            setMenuState(!menuState);
            history.push(link);
            window.location.reload();
        }

        function DropdownMenu() {
            const [activeMenu, setActiveMenu] = useState('main');
            const [menuHeight, setMenuHeight] = useState(null);
            const dropdownRef = useRef(null);

            useEffect(() => {
                setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
            }, [])

            function calcHeight(el) {
                const height = el.offsetHeight;
                setMenuHeight(height);
            }

            function DropdownItem(props) {
                return (
                    <a href="#" className={navbarstyle.menuItem}
                       onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
                        <span className={navbarstyle.iconButton}>{props.leftIcon}</span>
                        {props.children}
                        <span className={navbarstyle.iconRight}>{props.rightIcon}</span>
                    </a>
                );
            }

            function DoLogOut(props) {
                return (
                    <a href="#" className={navbarstyle.menuItem}
                       onClick={() => logOut()}>
                        <span className={navbarstyle.iconButton}>{props.leftIcon}</span>
                        {props.menuName}
                    </a>
                );
            }

            function LinkItem(props) {
                return (
                    <a href="#" className={navbarstyle.menuItem}
                       onClick={() => OpenPage(props.goToPage)}>
                        <span className={navbarstyle.iconButton}>{props.leftIcon}</span>
                        {props.menuName}
                    </a>
                );
            }

            return (
                <div className={navbarstyle.dropdown} style={{height: menuHeight}} ref={dropdownRef}>

                    <CSSTransition
                        in={activeMenu === 'main'}
                        timeout={500}
                        classNames="menu-primary"
                        unmountOnExit
                        onEnter={calcHeight}>
                        <div className={navbarstyle.menu}>
                            <LinkItem
                                leftIcon={<UserIcon/>}
                                goToPage={"/profile"}
                                menuName={"My profile"}
                            >
                            </LinkItem>
                            <LinkItem
                                leftIcon={<SearchIcon/>}
                                goToPage={"/search"}
                                menuName={"Search the database"}
                            >
                            </LinkItem>
                            <LinkItem
                                leftIcon={<AddNodeIcon/>}
                                goToPage={"/new"}
                                menuName={"Add a new node to the database"}
                            >
                            </LinkItem>
                            {showAdminBoard &&
                            <DropdownItem
                                leftIcon={<CogIcon/>}
                                rightIcon={<ChevronIcon/>}
                                goToMenu="Management">
                                Management
                            </DropdownItem>
                            }
                            <DoLogOut
                                leftIcon={<LogoutIcon/>}
                                menuName={"Logout"}
                            />
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={activeMenu === 'Management'}
                        timeout={500}
                        classNames="menu-secondary"
                        unmountOnExit
                        onEnter={calcHeight}>
                        <div className={navbarstyle.menu}>
                            <DropdownItem goToMenu="main" leftIcon={<ArrowIcon/>}>
                                Management
                            </DropdownItem>
                            <LinkItem leftIcon={<UsersIcon/>} goToPage={"/manageUsers"} menuName={"Manage users"}>Manage users</LinkItem>
                            <LinkItem leftIcon={<IndexSyncIcon/>} goToPage={"/getUpdate"} menuName={"Download updates"}>Resync indices</LinkItem>
                            <LinkItem leftIcon={<IndexSyncIcon/>} goToPage={"/resyncIndices"} menuName={"Resync indices"}>Resync indices</LinkItem>
                             <LinkItem leftIcon={<JigSawImageIcon/>} goToPage={"/matchImagesOffline"} menuName={"Media files management"}>Match images offline</LinkItem>
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={activeMenu === 'animals'}
                        timeout={500}
                        classNames="menu-secondary"
                        unmountOnExit
                        onEnter={calcHeight}>
                        <div className={navbarstyle.menu}>
                            <DropdownItem goToMenu="main" leftIcon={<ArrowIcon/>}>
                                <p>Animals</p>
                            </DropdownItem>
                            <DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
                            <DropdownItem leftIcon="🐸">Frog</DropdownItem>
                            <DropdownItem leftIcon="🦋">Horse?</DropdownItem>
                            <DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
                        </div>
                    </CSSTransition>
                </div>
            );
        }

        return (

            <NavBar>
                {currentUser && <NavItem icon={<CaretIcon/>}> <DropdownMenu></DropdownMenu> </NavItem>}
                {!currentUser && <NavLink icon={<LoginIcon/>} link={"/login"}/>}
            </NavBar>

        )
    }
;

export default NavBarGroup;

import React from "react";

export const API_URL = "http://10.0.0.227:8080/api/test/";

export const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$');

export const softwareVersion = "Version 1.0 Build 211010.0";

export const allowedHtml = ['p', 'a', 'img', 'strong', 'i', 'sup', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'em', 'ol', 'ul', 'li'];

export const defaultFontSize = "100%";
export const size0px = "0.0rem";
export const size1px = "0.0625rem";
export const size1dot4px = "0.0875rem";
export const size2px = "0.125rem";
export const size3px = "0.1875rem";
export const size4px = "0.25rem";
export const size5px = "0.3125rem";
export const size6px = "0.375rem";
export const size7px = "0.4375rem";
export const size7dot5px = "0.46875rem";
export const size8px = "0.5rem";
export const size9px = "0.5625rem";
export const size10px = "0.625rem";
export const size11px = "0.6875rem";
export const size12px = "0.75rem";
export const size13px = "0.8125rem";
export const size14px = "0.875rem";
export const size15px = "0.9375rem";
export const size16px = "1.0rem";
export const size17px = "1.0625rem";
export const size18px = "1.125rem";
export const size19px = "1.1875rem";
export const size20px = "1.25rem";
export const size21px = "1.3125rem";
export const size22px = "1.375rem";
export const size23px = "1.4375rem";
export const size24px = "1.5rem";
export const size25px = "1.5625rem";
export const size26px = "1.625rem";
export const size27px = "1.6875rem";
export const size28px = "1.75rem";
export const size29px = "1.8125rem";
export const size30px = "1.875rem";
export const size31px = "1.9375rem";
export const size32px = "2.0rem";
export const size33px = "2.0625rem";
export const size34px = "2.125rem";
export const size35px = "2.1875rem";
export const size36px = "2.25rem";
export const size37px = "2.3125rem";
export const size38px = "2.375rem";
export const size39px = "2.4375rem";
export const size40px = "2.5rem";
export const size41px = "2.5625rem";
export const size42px = "2.625rem";
export const size43px = "2.6875rem";
export const size44px = "2.75rem";
export const size45px = "2.8175rem";
export const size46px = "2.875rem";
export const size47px = "2.9375rem";
export const size48px = "3.0rem";
export const size49px = "3.0625rem";
export const size50px = "3.125rem";
export const size55px = "3.4375rem";
export const size60px = "3.75rem";
export const size64px = "4.0rem";
export const size65px = "4.0625rem";
export const size70px = "4.375rem";
export const size75px = "4.6875rem";
export const size76px = "4.75rem";
export const size80px = "5.0rem";
export const size85px = "5.3125rem";
export const size90px = "5.625rem";
export const size95px = "5.9375rem";
export const size100px = "6.25rem";
export const size108px = "6.75rem";
export const size120px = "7.5rem";
export const size130px = "8.125rem";
export const size134px = "8.375rem";
export const size140px = "8.75rem";
export const size150px = "9.375rem";
export const size160px = "10.0rem";
export const size180px = "11.25rem";
export const size200px = "12.5rem";
export const size240px = "15.0rem";
export const size250px = "15.625rem";
export const size300px = "18.75rem";
export const size350px = "21.875rem";
export const size400px = "25.0rem";
export const size405px = "25.3125rem";
export const size410px = "25.625rem";
export const size450px = "28.175rem";
export const size500px = "31.25rem";
export const size540px = "33.75rem";
export const size550px = "34.375rem";
export const size555px = "34.6875rem";
export const size600px = "37.5rem";
export const size650px = "40.625rem";
export const size670px = "41.875rem";
export const size700px = "43.75rem";
export const size750px = "46.875rem";
export const size800px = "50.0rem";
export const size840px = "52.5rem";
export const size900px = "56.25rem";
export const size1000px = "62.5rem";
export const size1200px = "75.0rem";

export const fontColorLightDark = "#4A7DBF"
export const fontColorDark = "#16224A"
export const fontColorLight = "#4FFFBF"
export const fontColorLightBright = "#FFBE54"
export const fontColorAlert = "#8E384E"
export const fontColorOk = "#457446"

export const fontColorMedium = "#37BEB7"
export const fontColorGray = "#83A5BC"
export const fontColorGray333 = "#333333"
export const fontColorLightGraylight = "#F0F0F0"
export const fontColorLightGray = "#DFDFDF"
export const fontColortextboxGrey = "#D6D7CD"
export const fontColorLogoGrey = "#B7B7AA"
export const fontColorLogoGrey80 = "rgba(183,183,170, 0.6)"
export const fontColorDarkGrey = "#515457"
export const fontColorVeryDarkGrey = "#161616"

export const colorWhite = "#FFFFFF"
export const colorWhite80 = "rgba(255,255,255, 0.8)"
export const colorWhite60 = "rgba(255,255,255, 0.6)"
export const colorWhite40 = "rgba(255,255,255, 0.4)"
export const colorWhite20 = "rgba(255,255,255, 0.2)"
export const colorWhite10 = "rgba(255,255,255, 0.1)"
export const colorBlack = "#000000"

export const logoColorBlack = "#262521"
export const logoColorYellow = "#f7d300"
export const logoColor1 = "#001D3F"
export const logoColor2 = "#D61D2C"
export const logoColor3 = "#0055FF"
export const logoColor4 = "#C700FF"
export const logoColor5 = "#D61D2C"
export const logoColor6 = "#495671"
export const logoColor7 = "#FFFFFF"
export const skdColor = "#001D3F"

export const maxWidth = "2500px"
export const maxWidthTablet = "991px"
export const maxWidthPhone = "768px"

export const menuHeight = size70px;
export const imageHeaderHeight = size600px;
export const imageHeaderHeightHalf = size450px;
export const imageHeaderHeightTwoThird = size550px;
export const imageHeaderHeightQuater = size400px;
// export const rodeHeaderHeight = size250px;
export const GreyblokHeight = size300px;
export const Greyblokwidth = size600px;
export const overlapTile = size40px;
export const menuTopSpace = size30px
export const searchMenuHeight = size90px
export const menuPadding = size50px;
export const menuPaddingMobile = size30px;

export const maxSearchResultString = 80;
export const maxCharlenght = 240;
export const maxTextHeight = size55px

export const h1FontSize = size48px;
export const h1FontSizeSmall = size32px;
export const h2FontSize = size21px;
export const h3FontSize = size19px;
export const h4FontSize = size17px;
export const h5FontSize = size15px;
export const h6FontSize = size12px;
export const ZoekresultaatFontSize = size30px;
export const pFontSize = size16px;
export const introFontSize = size22px;
export const bijschriftFontSize = size14px;
// export const h1LineHeight = size12px;
export const h1LineHeight = size48px;
export const h2LineHeight = size35px;
export const h3LineHeight = size28px;
export const h4LineHeight = size19px;
export const h5LineHeight = size15px;
export const h6LineHeight = size12px;
export const pLineHeight = size24px;
export const introLineHeight = size31px;
export const bijschriftLineHeight = size17px;

export const fontFamilyMain = "'Raleway', sans-serif"

export const numberPageOffset = 10
export const index = 12
export const baseUrl = "https://www.korpora.info"
export const imageUrl = "https://www.korpora.info/images/"
export const baseDirectoryCollection = "/collectie"
export const baseDirectoryCollectionPlusId = "/collectie/:id"
export const baseDirectorySearch = "/search"
export const baseDirectoryError = "/error"

export const videoWidth = size800px;
export const MapWidth = size600px;
export const MapHeight = size450px;
export const audioWidth = size25px;

export const isTrue = 'Ja';
export const isFalse = 'Nee';

import styled, {createGlobalStyle} from 'styled-components';
import * as Constants from './components/Authentication/Settings/Settings';

const GlobalStyle = createGlobalStyle`
  * {
    // font-weight: 400;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
  }

  html {
    font-size: ${Constants.defaultFontSize};
    color: ${Constants.colorBlack};
  }

  h1 {
    font-size: ${Constants.h1FontSize};
    font-weight: 700;
    line-height: ${Constants.h1LineHeight};
    color: ${Constants.logoColor1};
  }

  h2 {
    font-size: ${Constants.h2FontSize};
    font-weight: 600;
    line-height:${Constants.h2LineHeight};
    color: ${Constants.logoColor1};
  }

  h3 {
    font-size: ${Constants.h3FontSize};
    font-weight: 400;
    line-height: ${Constants.h3LineHeight};
    // color: ${Constants.colorWhite};
  }
  
  h6 {
    font-size: ${Constants.h6FontSize};
    font-weight: 400;
    line-height: ${Constants.h6LineHeight};
    // color: ${Constants.colorWhite};
  }

  p {
    font-size: ${Constants.pFontSize};
    // font-weight: 300;
    line-height: ${Constants.pLineHeight};
    // color: ${Constants.colorWhite};
  }
  
  a {
    color: ${Constants.logoColor4};
     &:hover {
        transition: all 0.3s ease-out;
        color: ${Constants.colorWhite80};
        background-color: ${Constants.logoColor4};
        cursor: pointer;
        transform: scale(1.05);
    }
  }
  
`;

export const Intro = styled.p`
  font-size: ${Constants.introFontSize};
  line-height: ${Constants.introLineHeight};
`

export const Bijschrift = styled.p`
  font-size: ${Constants.bijschriftFontSize};
  line-height: ${Constants.bijschriftLineHeight};
  color: ${Constants.fontColorGray333};
`

export const GeneralSec = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: ${Constants.colorWhite};
  padding: ${Constants.size5px} 0;
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: ${Constants.maxWidth};
  margin-right: auto;
  margin-left: auto;
  // padding-right: ${Constants.menuPadding};
  @media screen and (max-width: ${Constants.maxWidthTablet}) {
    padding-right:  ${Constants.menuPaddingMobile};
    padding-left:  ${Constants.menuPaddingMobile};
  }
`;

export const Button = styled.button`
  border-radius: ${Constants.size4px};
  background: ${({primary}) => (primary ? (Constants.fontColorLight) : (Constants.fontColorLightDark))};
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 64px' : '10px 20px')};
  color: ${Constants.colorWhite};
  font-size: ${({fontBig}) => (fontBig ? (Constants.h3FontSize) : (Constants.pFontSize))};
  outline: none;
  border: none;
  cursor: pointer;
  max-height: ${Constants.size50px};
  min-height: ${Constants.size50px};
  align-self: flex-end;

  &:hover {
    transition: all 0.3s ease-out;
    background: ${Constants.colorWhite};
    background-color: ${({primary}) => (primary ? (Constants.fontColorLight) : (Constants.fontColorLightDark))};
  }
  @media screen and (max-width: ${Constants.maxWidthPhone}) {
    width: 100%;
  }
`;

export const SearchButton = styled.button`
  line-height: 1;
  pointer-events: ${props => (props.barOpened ? "auto" : "none")};
  cursor: ${props => (props.barOpened ? "pointer" : "none")};
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
`;

export default GlobalStyle;

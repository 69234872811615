import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import UserService from "../../services/user.service";
import {Filler} from "../FooterStyles";


const GeneralTerms = () => {
    const [textField, setTextField] = useState("")
    const [nameField, setNameField] = useState("")
    const [dateField, setDateField] = useState("")
    useEffect(() => {

        let identifierHeadline1 = "f349d20e-7d62-4f84-b615-cba2f04628bf"

        UserService.getPublicData(identifierHeadline1).then(
            (response) => {
                console.log("START Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component")
                console.log(response.data)

                console.log("------------------------------------------------>>>>>>>>>>>>record.toString() = ")
                console.log("response.data.returnRecord = ")
                console.log(response.data.returnRecord)
                for (let i = 0; i < response.data.returnRecord.length; i++) {
                    console.log(response.data.returnRecord[i].nodeType)
                    if (response.data.returnRecord[i].nodeType.toString() === ("ImageObject")) {
                        // imageUrlArray.push(response.data.returnRecord[i].recordID.toString())
                    }
                    for (let j = 0; j < response.data.returnRecord[i].returnValues.length; j++) {
                        console.log("------- " + j)
                        console.log(response.data.returnRecord[i].returnValues[j].languageOfValue)
                        console.log(response.data.returnRecord[i].returnValues[j].fieldName)
                        if (response.data.returnRecord[i].returnValues[j].fieldName === "schema:name" &&
                            response.data.returnRecord[i].returnValues[j].languageOfValue === "nl") {
                            setNameField(response.data.returnRecord[i].returnValues[j].value)
                        }
                        if (response.data.returnRecord[i].returnValues[j].fieldName === "schema:text" &&
                            response.data.returnRecord[i].returnValues[j].languageOfValue === "nl") {
                            setTextField(response.data.returnRecord[i].returnValues[j].value)
                        }
                        if (response.data.returnRecord[i].returnValues[j].fieldName === "schema:datePublished") {
                            setDateField(response.data.returnRecord[i].returnValues[j].value)
                        }

                    }
                }
                // if (response.data.returnRecord.nodeType.contains("ImageObject")) {
                //     setIsImageObject(true)
                //     imageUrlArray.push(response.data.returnRecord.identifier)
                // }

                // console.log("searchString = " + response.data.searchString)
                // console.log("status = " + response.data.status)
                // console.log("timeStamp = " + response.data.timeStamp)
                // console.log("response.data.returnRecord start = ")
                // console.log(response.data.returnRecord)
                // console.log("response.data.returnRecord end = ")
                // console.log("END Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component Edit component")
            },
            (error) => {
                if (error.response.data.status === 401) {
                    return (<span>Error loading data</span>)
                }
            }
        );

    }, []);

    return (
        <span>
            <Helmet>
                <title>General Terms</title>
            </Helmet>

            <div className="headDivToolbar">
                <h1>{nameField}</h1>
                <p dangerouslySetInnerHTML={{__html: textField}}/><br/>
                <p>Version: {dateField}</p>
            </div>
            <Filler/>
        </span>
    );
};

export default GeneralTerms;

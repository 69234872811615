// innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged}
import React from "react";
import TextareaAutosize from 'react-textarea-autosize';
import editStyle from "../../../css/edittriple.module.css";

function EditUrl(props) {


    const handleChangeValue = (value) => {
        props.setUpdateNode({...props.updateNode, value: value})
        props.setIsChanged(true)
    }

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }

    return (
        <label className={editStyle.editInline}>
           {console.log("------> Start EditUrl")}
            {console.log(props.innerNode)}
            {console.log(props.updateNode)}
            {console.log(props.id)}
            {console.log("<------ End EditUrl")}
            <input name={props.id} type={"radio"} value={"URL"}
                   checked={props.updateNode.valueType === "URL"}
                   onChange={() => handleChangeType("URL")}/>
            &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            {props.updateNode.valueType === "URL" &&
                <TextareaAutosize type={"Text"} value={props.updateNode.value}
                                  pattern={"(https?|ftp)://(-\\.)?([^\\s/?\\.#-]+\\.?)+(/[^\\s]*)?$"}
                                  onChange={e => handleChangeValue(e.target.value)}
                                  cols={120}/>
            }
        </label>
    )
};
export default EditUrl

// innerNode={innerNode} updateNode={updateNode} setUpdateNode={setUpdateNode} setIsChanged={setIsChanged}
import React from "react";
import editStyle from "../../../css/edittriple.module.css";

function EditBoolean(props) {

    const x = "EditText"

    const handleChangeValue = (value) => {
        props.setUpdateNode({...props.updateNode, value: value})
        props.setIsChanged(true)
    }

    const handleChangeType = (type) => {
        props.setUpdateNode({...props.updateNode, value: "", valueType: type})
        // setNewTripleValue(this.state.value || "")
        props.setIsChanged(false)
        props.setShowSaveButton(true)
    }

    return (
        <label className={editStyle.editInline}>
            <input name={props.id} type={"radio"} value={"Boolean"}
                   checked={props.updateNode.valueType === "Boolean"}
                   onChange={() => handleChangeType("Boolean")}/>
            &nbsp;{props.innerNode.value}&nbsp;&nbsp;
            <select value={props.updateNode.value}
                    onChange={e => handleChangeValue(e.target.value)}>
                <option value={true}>true</option>
                <option value={false}>false</option>
            </select>
        </label>
    )
}
export default EditBoolean

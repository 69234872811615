import styled from "styled-components";
import * as Constants from "../../components/Authentication/Settings/Settings";
import {Container} from "../FooterStyles";
import {fontColorLightBright} from "../../components/Authentication/Settings/Settings";

export const FlexGrid = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${Constants.size20px};
  
  gap: ${Constants.size30px} ${Constants.size60px};
  width: 100%;
  ${Container}
`;

export const FlexButton = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${Constants.size20px};
  
  gap: ${Constants.size30px} ${Constants.size60px};
  width: 100%;
`;
export const SearchResult = styled.div`
  padding-bottom: ${Constants.size35px};
  padding-top: ${Constants.size20px};
  // margin-top: -${Constants.overlapTile};
  display: flex;
  position: relative;
  justify-content: center;
   width:  95%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${Constants.maxWidthPhone}) {
    // margin-top: -${Constants.size30px};
   }
    // border: solid 2px pink;
`;

export const H2Found = styled.h2`
  position: relative;
  font-size: ${Constants.ZoekresultaatFontSize};
  line-height: ${Constants.h1LineHeight};
  width: 95vw;
  min-width:  ${Constants.size800px};
   color: ${({lightTextDesc}) => (lightTextDesc ? Constants.colorWhite : Constants.colorBlack)};
  padding-top:  ${Constants.size75px};
  padding-left:  ${Constants.size5px};
  padding-bottom:  ${Constants.size45px};
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${Constants.maxWidthPhone}) {
    padding-top:  ${Constants.size55px};
    font-size: ${Constants.h3FontSize};
    line-height: ${Constants.h3LineHeight};
    color: ${Constants.colorWhite};
    text-align: left;
    min-width:  ${Constants.size0px};
    width: 80vw;
  }
`;

export const SearchBlock = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: ${Constants.colorBlack};
  background: ${({ lightBg }) => (lightBg ? Constants.fontColorLightBright : Constants.colorWhite)};
  max-width: ${Constants.size400px};
  min-width: ${Constants.size400px};
  max-height: ${Constants.size410px};
  min-height: ${Constants.size405px};
  &:hover {
    transition: all 0.3s ease-out;
    background-color: ${({lightBg}) => (lightBg ? (Constants.colorWhite) : (Constants.colorWhite))};
    cursor: pointer;
    transform: scale(1.05);
    }
  @media screen and (max-width: ${Constants.maxWidthTablet}) {
    max-width: ${Constants.size350px};
    min-width: ${Constants.size350px};
    max-height: ${Constants.size350px};
    min-height: ${Constants.size350px};
    }
`;

export const TextWrapper = styled.div`
  min-width: 100%;
  min-height: ${Constants.size500px};
  padding-bottom: ${Constants.size5px};
  padding-left: ${Constants.size15px};
  padding-right: ${Constants.size15px};
`;

export const Heading = styled.h3`
  text-align: left;
  margin-bottom: ${Constants.size5px};
  margin-top: ${Constants.size10px};
  color: ${Constants.colorBlack };
`;

export const Subtitle = styled.div`
  margin-bottom: ${Constants.size5px};
  font-size: ${Constants.size20px};
  text-align:  ${({ textLeft }) => (textLeft ? 'left' : 'center')};
  color: ${({ lightTextDesc }) => (lightTextDesc ? Constants.colorBlack : Constants.colorBlack)};
  width:  ${({ textFull }) => (textFull ? '100%' : '55%')};
  @media screen and (max-width: ${Constants.maxWidthTablet}) {
  width:  ${({ textFull }) => (textFull ? '100%' : '75%')};
   }
  @media screen and (max-width: ${Constants.maxWidthPhone}) {
    padding-left: ${Constants.size20px};
    padding-right: ${Constants.size20px};
    width: 95%;
}
`;

export const ImgWrapper = styled.div`
  max-width: ${Constants.size555px};
  max-height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-content: flex-end;
  margin-bottom: 0;
  padding-bottom: 0;
  overflow: hidden;
`;

export const SuggestButton = styled.button`
 border-radius: ${Constants.size4px};
  background: ${({primary}) => (primary ? (Constants.fontColorLight) : (Constants.fontColorLightDark))};
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 64px' : '10px 20px')};
  color: ${Constants.colorWhite};
  font-size: ${({fontBig}) => (fontBig ? (Constants.h3FontSize) : (Constants.pFontSize))};
  outline: none;
  border: none;
  cursor: pointer;
  max-height: ${Constants.size50px};
  min-height: ${Constants.size50px};
  align-self: flex-end;
`

import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router"
import UserService from "../../services/user.service";
import nodeStyle from "../../css/node.module.css"
import Update from "./TripleFunctions/Update";
import editstyle from "../../css/edittriple.module.css";
import iconstyle from "../../css/icons.module.css";
import {ReactComponent as CancelIcon} from "../Icons/Cancel.svg";
import {ReactComponent as SearchIcon} from "../Icons/Search.svg";
import {ReactComponent as CheckIcon} from "../Icons/Check.svg";
import ReactTooltip from 'react-tooltip';
import AuthService from "../../services/auth.service";


/**
 *
 * @param recordID
 * @param setAddType
 * @param toggleRefreshData
 * @param setToggleRefreshData
 * @returns {JSX.Element}
 * @constructor
 */
const TypesSelector = ({
                           recordID,
                           setAddType,
                           toggleRefreshData,
                           setToggleRefreshData
                       }) => {
    const history = useHistory();
    const typeList = [];
    const [toggleMetadata, setToggleMetadata] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const isInitialMount = useRef(true);
    const [triplesMetaData, setTriplesMetaData] = useState([]);
    const [appendTripple, setAppendTripple] = useState(false);
    const [startEdit, toStartEdit] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [typeStep1, setTypeStep1] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [searchString, setSearchString] = useState("");
    const [addedNode, setAddedNode] = useState({
        identifier: "",
        fieldName: "",
        value: "",
        valueType: "",
        languageOfValue: "",
        graph: "",
        doNotEdit: false,
        internalLink: false,
        imageObject: false
    });

    let instruction = "addType";

    const logOut = () => {
        AuthService.logout();
        history.push("/");
        window.location.reload();
    };

    const updateSearch = e => {
        setSearchValue(e.target.value);
        console.log("updateSearch updateSearch updateSearch = " + searchValue)
    }

    const setProperty = (value) => {
        console.log("setProperty + " + startEdit)
        setAddedNode({...addedNode, value: value, valueType: "iri"})
        toStartEdit(true)
        console.log("setProperty + " + startEdit)
    }

    const cancelAction = () => {
        setAddType(false)
        setToggleRefreshData(!toggleRefreshData)
    }

    const toggleTypeStep1 = () => {
        setTypeStep1(!typeStep1);
        setSearchString(searchValue);
        console.log("toggleTypeStep1 toggleTypeStep1 toggleTypeStep1 = " + searchValue)

    }

    useEffect(() => {
        console.log("isSuccess")
        if (isSuccess) {
            console.log("isSuccess: true")

            setToggleRefreshData(true)
            setAddType(false)
            cancelAction()

        } else {
            setIsChanged(false)
        }
        console.log("isSuccess")
    }, [isSuccess]);

    useEffect(() => {
        if (!isInitialMount.current) {
            console.log(" Update({mytriple, updateNode, instructionType, setIsLoading, setIsSuccess})")
            console.log(addedNode)
            Update({mytriple: addedNode, updateNode: addedNode, instructionType: instruction, setIsLoading, setIsSuccess})
            console.log(" Update({mytriple, updateNode, instructionType, setIsLoading, setIsSuccess})")
        }
    }, [startEdit])

    useEffect(() => {
        if (isInitialMount.current) {
            // isInitialMount.current = false;
        } else {
            if (toggleRefreshData === true) {
                setAddType(false)
            }
        }
    }, [toggleRefreshData])


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (!typeStep1) {
                let tempTypeList = searchString.split(" ");
                console.log("tempTypeList")
                console.log(tempTypeList)
                tempTypeList.map((x) => {
                    typeList.push(x)
                });
                setAddedNode({
                    ...addedNode,
                    identifier: recordID,
                    fieldName: "http://www.w3.org/1999/02/22-rdf-syntax-ns#type"
                })
                setIsLoading(true);
                console.log("useEffect: " + instruction)
                UserService.getMetaData("selectAllPossibleTypes", typeList, null, null).then(
                    (response) => {
                        console.log("selectPropertiesList")
                        console.log(response.data)
                        setTriplesMetaData(response.data.returnRecord[0].returnValues)
                        setIsLoading(false);
                    }
                    ,
                    (error) => {
                        cancelAction();
                        setIsLoading(false);
                        if (error.response.data.status === 401) {
                            logOut()
                        }
                    }
                );
                console.log("instruction " + instruction)
                console.log("searchString " + searchString)
                console.log("setAddType " + setAddType)
                console.log("toggleRefreshData " + toggleRefreshData)
                console.log("setToggleRefreshData " + setToggleRefreshData)
            }
        }
    }, [typeStep1]);

    return (
        <span>
            <div className={editstyle.showInlineType}>
                Select type&nbsp;&nbsp;{isLoading && <>Loading...</>}
            </div>
            {typeStep1 ?
                <div className={iconstyle.showInline}>
                    <input className="search" type="text" value={searchValue} placeholder={"Filter the types"} onChange={updateSearch}/>
                    <a data-tip data-for="SearchIcon" data-delay-show='1000' href="#" className={iconstyle.suggestionButton} onClick={() => toggleTypeStep1()}> <SearchIcon/>
                        <ReactTooltip id="SearchIcon" type="error">
                            <span>Perform search</span>
                        </ReactTooltip>
                    </a>
                </div>

                :
                <span>
                    <div>
                    <div  className={editstyle.showInlineType}>
                        {isLoading && <>Loading...</>}
                    </div>
                    <div  className={nodeStyle.datatable}>
                        {!startEdit ? <table>
                                <tbody>
                                <tr>
                                    <th>Add type</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                                {triplesMetaData.map((myTriple,i) =>
                                    <tr key={i}>
                                        <td>
                                            <a href="#" className={iconstyle.suggestionButton} onClick={() => {setProperty(myTriple.identifier)}}> <CheckIcon/> </a>
                                        </td>

                                        <td>
                                            {myTriple.identifier}
                                        </td>
                                        <td>
                                            <div dangerouslySetInnerHTML={{__html: myTriple.value}}/>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            :
                            <p>Inserting node {addedNode.value}</p>
                        }
                        {console.log("addedNode")}
                        {console.log(addedNode)}
                    </div>
                </div>
                </span>
            }
            <div className={editstyle.showInlineType}>
                <a data-tip data-for="CancelIcon" data-delay-show='1000' href="#" className={iconstyle.suggestionButton} onClick={() => cancelAction()}> <CancelIcon/></a> cancel select type
            </div>
            <br/>
            <br/>
        </span>

    );
};
export default TypesSelector;
